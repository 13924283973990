<template>
  <v-container>
    <!-- 検索エリア -->
    <v-row class="mt-2">
      <v-col cols="2" sm="2" md="2" lg="2">
        <h2>冷媒調査一覧</h2>
      </v-col>
      <v-col cols="auto" sm="auto" md="auto" lg="1">
        <YearPicker
          label="対象年度"
          v-model="searchCondition.targetYear"
          format="YYYY"
          selectType="month"
          :clearable="false"
          :hideDetails="true"
          suffix="年度"
          :changeEvt="search"
          :loading="loading"
        ></YearPicker>
      </v-col>
      <v-col cols="2">
        <v-text-field 
          label="会社名"
          v-model="searchCondition.companyName" 
          dense 
          :rules="[maxLength(50)]" 
          :maxlength="50"
          :disabled="loading"
          :clearable="true"
          :loading="loading"
          @change="search"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field 
          label="事業所名"
          v-model="searchCondition.officeName" 
          dense 
          :rules="[maxLength(50)]" 
          :maxlength="50"
          :disabled="loading"
          :clearable="true"
          :loading="loading"
          @change="search"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          label="ステータス"
          v-model="searchCondition.status"
          :items="ansStatus"
          dense
          :disabled="loading"
          :clearable="true"
          :loading="loading"
          @change="search"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="attribute == 2" cols="3" sm="3" md="2" lg="2">
        <v-btn class="" color="primary" width="200" :disabled="loading" @click="getRefrigerantDB()"><v-icon :small="true">mdi-download</v-icon>集計表ダウンロード</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-data-table 
          :headers="headers" 
          :items="officeList" 
          :loading="loading" 
          :dense="true" 
          :items-per-page="10" 
          :mobile-breakpoint="0" 
          :disable-pagination="true" 
          :hide-default-footer="true" 
          :fixed-header="true" 
          locale="ja-jp" 
          loading-text="読込中" 
          no-data-text="データがありません。" 
          class="table-border"
          :custom-filter="customFilter"
          height="calc(100vh - 200px)"
        >
          <template v-slot:item="{ item, index }">
            <!-- <tr :key="index" :class="[item.memberNo == memberNo ? 'selected-color' : '']"> -->
            <tr :key="index">
              <td class="center">
                {{ item.companyId }}
              </td>
              <td class="center">
                {{ item.memberNo }}
              </td>
              <td class="left">
                {{ item.companyName }}
              </td>
              <td class="left">
                {{ item.officeName }}
              </td>
              <td class="left">
                <v-text-field
                  v-model="item.branchName"
                  hide-details
                  dense
                  class="mb-1"
                  @input="item.branchEdit=true"
                  @change="saveBranch(item)"
                ></v-text-field>
              </td>
              <td class="left">
                {{ item.address }}
              </td>
              <td class="left">
                {{item.ansPeriodFrom | formatDateW}} ～ {{item.andPeriodTo | formatDateW}}<span v-if="!isBetweenDate(item.ansPeriodFrom, item.andPeriodTo)" class="text-error">受付期間外</span>
              </td>
              <td class="center">
                {{getAnsStatusText(item.status)}}
              </td>
              <td>
               {{ item.ansRemarks }}
              </td>
              <td class="center">
                <v-btn small class="primary" :height="20" :width="100" :disabled="loading || item.branchEdit" @click="gotoDetail('RefrigerantEdit', item.sid, item.memberNo, item.branch, item.branchName)">
                  <v-icon v-if="item.status == 0" :small="true">mdi-border-color</v-icon>
                  <v-icon v-else-if="item.status == 1" :small="true">mdi-alert</v-icon>
                  <v-icon v-else :small="true">mdi-chevron-down-circle</v-icon>
                  {{item.status == 0 ? '回答する' : 
                    item.status == 1 ? '回答編集' : 
                    item.status == 2 ? '回答済み' : 
                    item.status == 3 ? '確定済み' :
                    item.status == 4 ? '確認中' :
                    item.status == 5 ? '保留中' :
                                        '確認済み'}}
                </v-btn>
                <v-btn v-if="item.branch == getFirstBranch(item.memberNo)" small class="primary ml-1" :height="20" :width="100" :disabled="loading" @click="addBranch(index, item)">
                  区別追加
                </v-btn>
                <!-- <v-btn v-else small class="primary ml-1" :height="20" :width="100" :disabled="loading || !item.branchEdit" @click="saveBranch(item)">
                  区別保存
                </v-btn> -->
                <v-btn v-else small class="secondary ml-1" :height="20" :width="100" :disabled="loading" @click="deleteBranch(item)">
                  区別削除
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    
    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>
  </v-container>
</template>

<script>
import Debug from "../Lib/Debug";
import Message from "../Lib/Message";
import YearPicker from "./common/YearPicker.vue";
import dayjs from "dayjs";
import Mixin from "../mixins/const.js";
import Download from "../model/download";
import Spinner from 'vue-simple-spinner'
import saveAs from "file-saver";

import Refrigerant from "../model/refrigerant";
import RefrigerantAnswer from "../model/refrigerantAnswer";

export default {
  components: {
    YearPicker,
    Spinner,
  },

  mixins: [Mixin],

  data: () => ({
    valid: true,
    loading: false,

    show: true,

    memberNo: '',
    attribute: 0,

    // テーブルヘッダー
    headers: [
      { text: "登録番号", align: "center", sortable: true, value: "companyId", width: "100px" },
      { text: "会員番号", align: "center", sortable: true, value: "memberNo", width: "100px" },
      { text: "会社名", align: "center", sortable: true, value: "companyName", width: "13%" },
      { text: "事業所名", align: "center", sortable: true, value: "officeName", width: "13%" },
      { text: "区別(棟)", align: "center", sortable: true, value: "branchName", width: "10%" },
      { text: "所在地", align: "center", sortable: true, value: "address", width: "23%" },
      { text: '回答受付期間', align: 'center', sortable: true, value: 'ansPeriodFromTo', width: "14%" },
      { text: "ステータス", align: "center", sortable: true, value: "status", width: "115px" },
      { text: "備考", align: "center", sortable: true, value: "ansRemarks", width: "23%" },
      { text: "", align: "center", sortable: false, value: "action", width: "250px" },
    ],

    // 回答データ一覧
    officeList: [],

    // 検索条件
    searchCondition: {
      screenType: 'RefrigerantList',
      companyId: null,
      associationId: null,
      companyName: '',
      officeName: '',
      targetYear: '',
      searchString: null,
      status: null,
    },

    // ぐるぐるメッセージ
    actionMessage: '',
  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    if(this.attribute == 0) {
      // 事業所ユーザ
      this.memberNo = this.$store.getters.getMemberNo;    // 会員番号
    } else {
      // 協会ユーザ
      this.memberNo = this.$store.getters.getAssociation.association_id;    // 会員番号
    }
    
    const str = this.$store.getters.getStoreSearchCondition;
    if (str.screenType == 'RefrigerantList') {
      // 回答画面から戻った場合
      this.searchCondition = str;
    } else {
      // 初期値
      const params = this.$route.params;
      if('targetYear' in params) {
        // 回答状況チェックから
        this.searchCondition.targetYear = params.targetYear;
      } else {
        // メニューから
        this.searchCondition.targetYear = dayjs().format().substr(0, 7);  // 実施年(YYYY-MM)
      }
    }

    // 属性による検索条件の設定
    if(this.attribute == 0) {
      // 事業所ユーザは自会社の事業所データのみ
      this.searchCondition.companyId = this.$store.getters.getCompanyId;

      // 事業所ユーザは、自分がメール宛先にある場合のみ
      this.searchCondition.email = this.$store.getters.getUserid;

    } else if(this.attribute == 1) {
      // 都道府県協会ユーザは自協会に所属する会社のデータのみ
      this.searchCondition.associationId = this.memberNo;
    }

    this.loadList(); // 表示更新
  },

  mounted: async function () {},

  methods: {
    /** 表示更新 */
    async loadList() {
      Debug.log("function[loadList]");
      this.loading = true;

      // this.companyId = this.$store.getters.companyId; // 会社IDをstoreから取得
      try {
        //const res = await Refrigerant.getData(this.companyId, this.targetYear);
        const res = await Refrigerant.search(this.searchCondition);
        Debug.log(res.data);
        this.officeList = res.data;
      } catch (error) {
        Message.err(error, "冷媒調査一覧を取得できませんでした");
      }

      Debug.log(this.officeList);
      this.loading = false;
    },

    search() {
      Debug.log("function[search]");
      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      this.loadList();
    },

    /** 詳細ページに遷移 */
    gotoDetail(name, surveyId, memberNo, branch, branchName) {
      Debug.log("function[gotoDetail]");
      this.$store.commit("setTargetSurveyId", surveyId); // クリックした調査IDをstoreに保存
      this.$store.commit("setTargetMemberNo", memberNo); // クリックした会員番号をstoreに保存
      this.$store.commit("setTargetBranch", branch); // クリックした区別をstoreに保存
      this.$store.commit("setTargetBranchName", branchName); // クリックした区別名をstoreに保存

      // 同タブで開く
      this.$router.push({ name: name });
    },

    // 区別追加
    async addBranch(index, item) {
      Debug.log('addBranch');

      // 事業所の最後に追加
      // const branchCount = this.officeList.filter(function(el){
      //   return el.memberNo == item.memberNo;
      // }).length;

      // 事業所の最大区別
      const lastBranch = this.getLastBranch(item.memberNo);

      let tmpAddItem = Object.assign({}, item);
      tmpAddItem.status = 0;
      tmpAddItem.branch = lastBranch+1;
      tmpAddItem.branchName = null;
      tmpAddItem.branchEdit = true;

      // DB保存
      this.saveBranch(tmpAddItem);

      //this.officeList.splice(index+branchCount, 0, tmpAddItem);
    },
    // 区別保存
    async saveBranch(item) {
      Debug.log('saveBranch');
      this.loading = true;
      try {
        await RefrigerantAnswer.setBranch(item);
      } catch (error) {
        Message.err(error, "区別を保存できませんでした");
      }

      item.branchEdit = false;
      this.loading = false;

      this.search();
    },
    // 区別削除
    async deleteBranch(item) {
      Debug.log('deleteBranch');

      if (!confirm("回答データは削除されます。よろしいですか？")) return;

      this.loading = true;
      try {
        await RefrigerantAnswer.delBranch(item.sid, item.memberNo, item.branch);
      } catch (error) {
        Message.err(error, "区別を削除できませんでした");
      }

      item.branchEdit = false;
      this.loading = false;

      this.search();
    },

    // 事業所の最小の区分
    getFirstBranch(memberNo) {
      Debug.log('getFirstBranch');
      const member = this.officeList.filter(function(el){
        return el.memberNo == memberNo;
      });

      // 区分のみを配列で取得
      const branchList = member.map(function (b) {
        return b.branch;
      });

      // 最小値を取得
      return Math.min.apply(null, branchList);
    },
    // 事業所の最大の区分
    getLastBranch(memberNo) {
      Debug.log('getLastBranch');
      const member = this.officeList.filter(function(el){
        return el.memberNo == memberNo;
      });

      // 区分のみを配列で取得
      const branchList = member.map(function (b) {
        return b.branch;
      });

      // 最大値を取得
      return Math.max.apply(null, branchList);
    },

    // ステータスのフィルター
    customFilter(value, search){
      return typeof value === 'number' && this.getAnsStatusText(value) == search;
    },

    /** 冷媒調査集計 */
    async getRefrigerantDB() {
      try {
        this.actionMessage = "冷媒調査集計を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await Refrigerant.sumRefrigerantDB(this.searchCondition);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data;
          Download.download(downloadFile)
            .then(res => {
              const targetYearStr = dayjs(this.searchCondition.targetYear).format('YYYY');
              saveAs(res.data, '冷媒調査集計('+targetYearStr+'版).xlsx');
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "冷媒調査集計を作成できませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },
  },

  filters: {
    formatDate(date) {
      // YYYY/MM/DD になおす
      if (!date) return "";
      const formatDate = dayjs(date);

      const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"][formatDate.day()];

      return `${formatDate.format("YYYY/MM/DD")}(${dayOfWeek})`;
    },
  },
};
</script>

<style scoped>
.disablecolor {
  background: #bbbbbb;
}

/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}
</style>
