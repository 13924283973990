<template>
  <v-container>
    <AlertSnackbar v-if="attribute == 0 && contactData.status < 3" :disp="!isBetweenDate(dest.ans_period_from, dest.and_period_to)" color="warning" message="回答受付期間外のため、確定できません"></AlertSnackbar>
    <AlertSnackbar v-else :disp="contactData.status >= 3" color="accent" :message="contactData.status == 3 ? '回答は確定済みです' : 
                                                                                    contactData.status == 4 ? '回答は確認中です' :
                                                                                    contactData.status == 5 ? '回答は保留中です' : '回答は確認済みです'"></AlertSnackbar>
    <v-row>
      <v-col>
        <div class="d-flex">
          <h2>{{officeData.companyName}}様 {{officeData.officeName}} {{branchName}} 冷媒調査</h2><v-icon class="ml-1" v-on:click="show()">mdi-help-circle-outline</v-icon>
        </div>
      </v-col>
      <v-col class="text-center">
        <div class="d-flex justify-end">
          <v-btn class="btn_font primary mr-2" width="150" v-on:click="showImport()" :disabled="loading || ((attribute == 0) && (contactData.status >= 3))" >回答票インポート</v-btn>
          <v-btn class="btn_font primary mr-2" width="120" v-on:click="saveData()" :disabled="loading || ((attribute == 0) && (contactData.status >= 3))" >一時保存</v-btn>
          <v-btn 
            class="btn_font primary mr-2" 
            width="120" 
            v-on:click="answerData()" 
            :disabled="loading || ( (attribute == 0) && ((contactData.status >= 3) || !isBetweenDate(dest.ans_period_from, dest.and_period_to)) )" >確定
          </v-btn>
          <v-btn v-if="attribute != 0 && contactData.status >= 3"
            class="btn_font primary mr-2" width="120" v-on:click="sendBackData()" :disabled="loading" >確定解除</v-btn>
          <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading">キャンセル</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col class="pt-1">
        <v-tabs class="" :slider-size=0 v-model="tabId">
          <v-tab href="#tab-base" class="tab-width-200">基礎情報<v-icon v-if="!this.formBaseValid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-ans" class="tab-width-200">回答票<v-icon v-if="!this.formAnsValid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-note" class="tab-width-200">備考<v-icon v-if="!this.formNoteValid" :small="true" color="warning">mdi-alert</v-icon></v-tab>

          <!-- 基礎情報 -->
          <v-tab-item value="tab-base" class="tab-item" :transition="false" eager>
            <v-form ref="formBase" v-model="validBase" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                  <v-alert type="info" border="left" colored-border elevation="1" class="pt-1 pb-1 mb-0">
                    <ul>
                      <li>①この調査票は、事業所ごとにご回答ください。</li>
                      <li>②調査の対象期間は{{ contactData.targetMonthFrom }}～{{ contactData.targetMonthTo }}です。</li>
                      <!-- <li>③この用紙にご回答のうえ、E-メールまたはFAXにてご返信ください。</li> -->
                      <span class="add-note">{{ addNote }}</span>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>

              <v-row class="mt-2">
                <v-col class="d-flex align-center" cols="4" sm="4" md="4" lg="4">
                  <DatePicker
                    label="回答受付期間 開始"
                    v-model="dest.ans_period_from"
                    format="YYYY/MM/DD"
                    selectType="date"
                    :readonly="attribute == 0 || !editAnsPeriodMode"
                    :rules="[checkStartEndDate(dest.ans_period_from, dest.and_period_to)]"
                    :clearable="false"
                    :loading="loading"
                  ></DatePicker>
                  ～
                  <DatePicker
                    label="回答受付期間 終了"
                    v-model="dest.and_period_to"
                    format="YYYY/MM/DD"
                    selectType="date"
                    :readonly="attribute == 0 || !editAnsPeriodMode"
                    :rules="[checkStartEndDate(dest.ans_period_from, dest.and_period_to)]"
                    :clearable="false"
                    :loading="loading"
                  ></DatePicker>
                </v-col>
                <v-col v-if="attribute != 0" cols="4">
                  <v-btn v-if="!editAnsPeriodMode" class="btn_font primary" width="120" v-on:click="editAnsPeriod()" :disabled="loading" >受付期間変更</v-btn>
                  <v-btn v-else class="btn_font primary" width="120" v-on:click="registAnsPeriod()" :disabled="loading" >保存</v-btn>
                  <v-btn v-if="editAnsPeriodMode" class="ml-5 btn_font primary" width="120" v-on:click="cancelAnsPeriod()" :disabled="loading" >キャンセル</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-select
                    label="ステータス"
                    v-model="contactData.status"
                    :items="ansStatus"
                    dense
                    :readonly="attribute == 0"
                    :clearable="false"
                    :loading="loading"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2">
                  <v-text-field label="会員番号" v-model="officeData.memberNo" readonly hide-details dense :loading="loading"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field label="会社名" v-model="officeData.companyName" readonly hide-details dense :loading="loading"></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field label="事業所名" v-model="officeData.officeName" readonly hide-details dense :loading="loading"></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field label="区別(棟)" v-model="contactData.branchName" :readonly="attribute == 0 && contactData.status >= 3" dense :loading="loading" hide-details></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field label="所在地" v-model="officeData.addressBldg" readonly hide-details dense :loading="loading"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6" lg="6">
                  <NumberTextField 
                    label="お届け所管容積(㎥)"
                    hint="日冷倉協届出の所管容積です。変更あれば修正願います。"
                    v-model="contactData.volumeTotal"
                    persistent-hint
                    :readonly="attribute == 0 && contactData.status >= 3"
                    suffix="㎥"
                    :loading="loading"
                    :orgRoundStep="1"
                    :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                    :changeEvt="formChanged"
                  ></NumberTextField>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <h4>記入内容に関する問い合わせ先</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field
                    label="担当者名" 
                    v-model="contactData.responderName" 
                    :readonly="attribute == 0 && contactData.status >= 3" 
                    dense 
                    :loading="loading"
                    :rules="[]" 
                    :maxlength="50"
                    @change="formChanged"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field 
                    label="電話" 
                    v-model="contactData.tel" 
                    :readonly="attribute == 0 && contactData.status >= 3" 
                    dense 
                    :loading="loading"
                    :rules="[]" 
                    :maxlength="50"
                    @change="formChanged"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field 
                    label="メール" 
                    v-model="contactData.email" 
                    :readonly="attribute == 0 && contactData.status >= 3" 
                    dense
                    :rules="[validEmail]"
                    :loading="loading"
                    :maxlength="100"
                    :counter="100"
                    @change="formChanged"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="justify-end mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-ans')" :disabled="loading">次へ<v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item value="tab-ans" class="tab-item" :transition="false" eager>
            <v-form ref="formAns" v-model="validAns" lazy-validation>
              <v-row class="mt-1">
                <v-col cols="auto" class="pt-0 pb-0">
                  <span class="blue-text annotation" v-on:click="show()">記入要項</span>
                  <div class="text-error">※冷媒名がプルダウンリストに無いものは記入欄に冷媒名を記入してください</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="pt-0 pb-0">
                  <div class="input-prev-data-button-wrap">
                    <v-btn class="btn_font primary" width="175" v-on:click="inputPrevData()" :disabled="(loading) || (attribute == 0 && contactData.status >= 3)" >前年度入力値のコピー</v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <!-- <div class="ref-table-wrap"> -->
                    <v-data-table 
                      :hide-default-header="true"
                      :hide-default-footer="true"
                      class="no-hover-effect table-border"
                      height="calc(100vh - 370px)"
                    >
                      
                      <template v-slot:body="{}">
                        <colgroup>
                          <col>
                          <col>
                          <col style="width:220px;">
                          <col style="width:120px;">
                          <col style="width:120px;">
                          <col style="width:120px;">
                          <col style="width:220px;">
                          <col style="width:120px;">
                          <col style="width:120px;">
                          <col style="width:120px;">
                          <col style="width:170px;">
                          <col style="width:180px;">
                        </colgroup>
                        <thead>
                          <tr>
                            <th rowspan="2" class="text-center" width="180">使用用途</th>
                            <th rowspan="2" class="text-center" width="120">対象所管容積<br />[㎥](立方メートル)</th>
                            <th rowspan="2" class="text-center" width="120">冷却方式</th>
                            <th colspan="4" class="text-center" width="580">使用冷媒</th>
                            <th colspan="4" class="text-center" width="580">冷媒2(二元冷凍方式の場合のみ記入)※3</th>
                            <th rowspan="2" class="text-center worker" width="170">冷媒の充填実施者</th>
                            <th rowspan="2" class="text-center" width="180">記入欄</th>
                          </tr>
                          <tr>
                            <th class="text-center" width="220">冷媒名</th>
                            <th class="text-center" width="120">初期充填量<br />[kg]</th>
                            <th class="text-center" width="120">年間補充量<br />[kg]</th>
                            <th class="text-center" width="120">購入価格<br />[kg/円]</th>
                            <th class="text-center" width="220">冷媒名</th>
                            <th class="text-center" width="120">初期充填量<br />[kg]</th>
                            <th class="text-center" width="120">年間補充量<br />[kg]</th>
                            <th class="text-center" width="120">購入価格<br />[kg/円]</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="data in surveyDatas" :key="data.index">
                            <td class="center">
                              <!-- 使用用途 -->
                              <v-select v-model="data.usage" :items="refrigerantUsage" :readonly="attribute == 0 && contactData.status >= 3" dense :clearable="(attribute == 0 && contactData.status < 3) || (attribute != 0)" :loading="loading" @change="formChanged"></v-select>
                            </td>
                            <td class="center">
                              <!-- 対象所管容積 -->
                              <NumberTextField 
                                v-model.number="data.volume" 
                                class="right-input" 
                                :readonly="attribute == 0 && contactData.status >= 3" 
                                dense 
                                suffix="㎥"
                                :loading="loading"
                                :orgRoundStep="10"
                                :rules="[maxValLocaleJP(9999999.9), minValLocaleJP(0)]" 
                                :changeEvt="formChanged"
                              ></NumberTextField>
                            </td>
                            <td class="center">
                              <!-- 冷却方式 -->
                              <v-select v-model="data.method" :items="refrigerantMethod" :readonly="attribute == 0 && contactData.status >= 3" dense :clearable="(attribute == 0 && contactData.status < 3) || (attribute != 0)" :loading="loading" @change="formChanged"></v-select>
                            </td>
                            <td class="center refrigerant-name" width="220">
                              <!-- 冷媒名 -->
                              <v-select v-model="data.singleName" item-text="text" item-value="value" :items="refrigerantList" :readonly="attribute == 0 && contactData.status >= 3" dense :clearable="(attribute == 0 && contactData.status < 3) || (attribute != 0)" :loading="loading" @change="formChanged"></v-select>
                            </td>
                            <td class="center refrigerant-item" width="120">
                              <!-- 初期充填量 -->
                              <NumberTextField 
                                v-model="data.singleInit" 
                                class="right-input" 
                                :readonly="attribute == 0 && contactData.status >= 3" 
                                dense 
                                suffix="kg"
                                :loading="loading"
                                :orgRoundStep="10"
                                :rules="[maxValLocaleJP(9999999.9), minValLocaleJP(0)]"
                                :changeEvt="formChanged"
                              ></NumberTextField>
                            </td>
                            <td class="center refrigerant-item" width="120">
                              <!-- 年間充填量 -->
                              <NumberTextField 
                                v-model="data.singleYear" 
                                class="right-input" 
                                :readonly="attribute == 0 && contactData.status >= 3" 
                                dense 
                                suffix="kg"
                                :loading="loading"
                                :orgRoundStep="10"
                                :rules="[maxValLocaleJP(9999999.9), minValLocaleJP(0)]"
                                :changeEvt="formChanged"
                              ></NumberTextField>
                            </td>
                            <td class="center refrigerant-item" width="120">
                              <!-- 購入価格 -->
                              <NumberTextField 
                                v-model="data.singlePrice" 
                                class="right-input" 
                                :readonly="attribute == 0 && contactData.status >= 3" 
                                dense 
                                suffix="円"
                                :loading="loading"
                                :orgRoundStep="1"
                                :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                                :changeEvt="formChanged"
                              ></NumberTextField>
                            </td>

                            <!-- 冷却方式が2元の場合 -->
                            <template v-if="data.method == 1">
                              <td class="center refrigerant-name">
                                <!-- 冷媒名2 -->
                                <v-select v-model="data.dualName" item-text="text" item-value="value" :items="refrigerantList" :readonly="attribute == 0 && contactData.status >= 3" dense :clearable="(attribute == 0 && contactData.status < 3) || (attribute != 0)" :loading="loading" @change="formChanged"></v-select>
                              </td>
                              <td class="center refrigerant-item">
                                <!-- 初期充填量2 -->
                                <NumberTextField 
                                  v-model="data.dualInit" 
                                  class="right-input" 
                                  :readonly="attribute == 0 && contactData.status >= 3" 
                                  dense 
                                  suffix="kg"
                                  :loading="loading"
                                  :orgRoundStep="10"
                                  :rules="[maxValLocaleJP(9999999.9), minValLocaleJP(0)]"
                                  :changeEvt="formChanged"
                                ></NumberTextField>
                              </td>
                              <td class="center refrigerant-item">
                                <!-- 年間充填量2 -->
                                <NumberTextField 
                                  v-model="data.dualYear" 
                                  class="right-input" 
                                  :readonly="attribute == 0 && contactData.status >= 3" 
                                  dense 
                                  suffix="kg"
                                  :loading="loading"
                                  :orgRoundStep="10"
                                  :rules="[maxValLocaleJP(9999999.9), minValLocaleJP(0)]"
                                  :changeEvt="formChanged"
                                ></NumberTextField>
                              </td>
                              <td class="center refrigerant-item">
                                <!-- 購入価格2 -->
                                <NumberTextField 
                                  v-model="data.dualPrice" 
                                  class="right-input" 
                                  :readonly="attribute == 0 && contactData.status >= 3" 
                                  dense 
                                  suffix="円"
                                  :loading="loading"
                                  :orgRoundStep="1"
                                  :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                                  :changeEvt="formChanged"
                                ></NumberTextField>
                              </td>
                            </template>

                            <!-- 冷却方式が1元の場合 -->
                            <template v-else>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </template>

                            <td class="center worker">
                              <!-- 冷媒の充填実施者 -->
                              <v-select v-model="data.worker" :items="refrigerantWorker" :readonly="attribute == 0 && contactData.status >= 3" dense :clearable="(attribute == 0 && contactData.status < 3) || (attribute != 0)" :loading="loading" @change="formChanged"></v-select>
                            </td>
                            <td class="center">
                              <!-- 記入欄 -->
                              <v-text-field v-model="data.remarks" :readonly="attribute == 0 && contactData.status >= 3" dense :loading="loading" @change="formChanged"></v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  <!-- </div> -->
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2" class="text-center">
                  <NumberTextField label="冷蔵庫 冷凍庫" suffix="㎥" v-model.number="warehouseVolumeCalc" readonly :loading="loading" hide-details></NumberTextField>
                </v-col>
                <v-col cols="2" sm="2" md="2" lg="2" class="text-center">
                  <NumberTextField label="荷捌室" suffix="㎥" v-model.number="cargoVolumeCalc" readonly :loading="loading" hide-details></NumberTextField>
                </v-col>
                <v-col cols="2" sm="2" md="2" lg="2" class="text-center">
                  <NumberTextField label="製氷" suffix="㎥" v-model.number="iceVolumeCalc" readonly :loading="loading" hide-details></NumberTextField>
                </v-col>
                <v-col cols="2" sm="2" md="2" lg="2" class="text-center">
                  <NumberTextField label="凍結" suffix="㎥" v-model.number="frozenVolumeCalc" readonly :loading="loading" hide-details></NumberTextField>
                </v-col>
                <v-col cols="2" sm="2" md="2" lg="2" class="text-center">
                  <NumberTextField label="その他" suffix="㎥" v-model.number="otherVolumeCalc" readonly :loading="loading" hide-details></NumberTextField>
                </v-col>
              </v-row>

              <v-row class="justify-space-between mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-base')" :disabled="loading" ><v-icon>mdi-chevron-left</v-icon>前へ</v-btn>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-note')" :disabled="loading">次へ<v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item value="tab-note" class="tab-item" :transition="false" eager>
            <v-form ref="formNote" v-model="validNote" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea 
                    label="備考欄" 
                    v-model="contactData.ansRemarks" 
                    :readonly="attribute == 0 && contactData.status >= 3" 
                    :loading="loading"
                    :maxLength="1000"
                    :counter="1000"
                    @change="formChanged"
                  ></v-textarea>
                </v-col>
              </v-row>

              <!-- 追加設問 -->
              <v-row v-if="qaList.length > 0">
                <v-col>
                  <h3>その他の追加設問</h3>
                </v-col>
              </v-row>
              <v-row v-for="(qaData, index) in qaList" :key="qaData.qaSid">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    :label="(index+1)+'.'+qaData.question"
                    v-model="qaData.answer"
                    hide-details
                    dense
                    :readonly="attribute == 0 && contactData.status >= 3"
                    :clearable="false"
                    :rules="[]"
                    :maxLength="100"
                    :loading="loading"
                    rows="2"
                    @change="formChanged"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row class="justify-start mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-ans')" :disabled="loading" ><v-icon>mdi-chevron-left</v-icon>前へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <!-- アクション -->
    <v-row class="mt-0 mb-0 pt-0 pb-0">
      <v-col class="pt-0 pb-0">
        <!-- <h4>このたびはお忙しいところご回答いただき、まことにありがとうございました。</h4> -->
        <div>
          ■連絡先(一社)日本冷蔵倉庫協会技術部&nbsp;&nbsp;&nbsp;&nbsp;E-ﾒｰﾙ：gijutsu@jarw.or.jp&nbsp;&nbsp;&nbsp;&nbsp;FAX：03-3536-1031
        </div>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="4" sm="4" md="4" lg="4" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="saveData()" :disabled="loading">一時保存</v-btn>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="answerData()" :disabled="loading">回答する</v-btn>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading">キャンセル</v-btn>
      </v-col>
    </v-row> -->
    <!-- <v-row class="justify-space-between">
      <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="saveData()" :disabled="loading || ((attribute == 0) && (contactData.status >= 3))" >一時保存</v-btn>
      </v-col>
      <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn 
          class="btn_font primary" 
          width="120" 
          v-on:click="answerData()" 
          :disabled="loading || ( (attribute == 0) && ((contactData.status >= 3) || !isBetweenDate(dest.ans_period_from, dest.and_period_to)) )" >確定</v-btn>
      </v-col>
      <v-col v-if="attribute != 0 && contactData.status >= 3" cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="sendBackData()" :disabled="loading" >確定解除</v-btn>
      </v-col>
      <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading">キャンセル</v-btn>
      </v-col>
    </v-row> -->

    <!-- modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="true" :scrollable="true" 
                                height="auto" width="80%" id="vm--modal">
      <div class="modal-wrap">
        <div class="d-flex modal-header justify-space-between">
          <h2>記入要項</h2>
          <v-icon x-large v-on:click="hide()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          ・使用用途(冷蔵庫 冷凍庫・荷捌室・製氷・凍結・その他)に分けて記入してください。<br />
          ・同一冷媒を使用している冷凍ユニットは冷媒量を合算して入力してください。<br />
          ・二元冷凍の場合は、使用冷媒に一元冷媒、冷媒2に二元冷媒を記入してください。<br />
          ※二元冷凍は、1基の冷凍ユニットに2種類の冷媒を使用しているもの<br />
          ・冷媒リストにないものは「その他」として入力してください。<br />
          ・対象所管容積については、冷蔵庫(保管)スペースを立方メートルで記入してください。<br />
          ・冷媒の充填は、自所で充填しているか、業者に依頼しているかを入力してください(必須)。<br />
          ※昨年度冷媒を充填していない場合でも、通常の冷媒の充填実施者を参考のため入力願います。
        </div>
      </div>
    </modal>

    <!-- modal -->
    <modal name="import-modal-dialog" :draggable="false" :clickToClose="true" :scrollable="true" 
                                height="200px" width="80%" id="vm--modal-import">
      <div class="modal-wrap">
        <div class="d-flex modal-header mt-8 pb-8 justify-space-between">
          <h2>回答票インポート</h2>
          <v-icon x-large v-on:click="hideImport()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <v-container>
            <v-row class="">
              <v-col cols="12">
                回答票Excelを選択してください。
              </v-col>
            </v-row>
            <v-row class="justify-space-between">
              <v-col cols="8">
                <v-file-input 
                  label="回答票Excelファイル" 
                  v-model="excelFile" 
                  dense 
                  accept=".xlsx"
                  truncate-length="30"
                  prepend-icon="mdi-import"
                ></v-file-input>
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <v-btn 
                  class="btn_font primary mr-2" 
                  width="150" 
                  v-on:click="importExcel()"
                  :disabled="loading || (excelFile == null)" >
                  回答票インポート
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </modal>

  </v-container>

</template>

<script>
import Debug from "../Lib/Debug";
import Message from "../Lib/Message";
import Util from "../Lib/Util";
import Mixin from "../mixins/const.js";
import XlsxImport from '../mixins/excel_import';
import * as XLSX from "xlsx";

import NumberTextField from "./common/Number_text_field.vue";
import DatePicker from "./common/DatePicker.vue";

import RefrigerantAnswer from "../model/refrigerantAnswer.js";
import RefrigerantDest from "../model/refrigerantDest.js";
import Mrefrigerant from "../model/m_refrigerant.js";

import AlertSnackbar from "./common/AlertSnackbar.vue";

export default {
  mixins: [Mixin, XlsxImport],

  components: { NumberTextField, DatePicker, AlertSnackbar },

  data: () => ({
    validBase: true,
    validAns: true,
    validNote: true,
    loading: false,

    // ログインユーザ属性
    attribute: 0,

    // オープンするパネル(index)
    openPanel: 0,

    haveBinary: false,
    refrigerantList: [],

    field: [],

    // 回答データ
    targetYear: null,
    officeData: {},
    contactData: {},
    surveyDatas: [
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
      {
        sid: null,
        usage: null,
        volume: null,
        method: null,
        singleName: null,
        singleInit: null,
        singleYear: null,
        singlePrice: null,
        dualName: null,
        dualInit: null,
        dualYear: null,
        dualPrice: null,
        worker: null,
        remarks: null,
      },
    ],

    // 前年回答データ
    prevSurveyDatas: [],

    // 宛先
    dest: {},
    // 追加の注意書き
    addNote: "",
    // 追加設問
    qaList: [],

    // 更新対象
    surveyId: "",
    memberNo: "",
    branch: "",
    branchName: "",

    // 回答受付期間変更
    editAnsPeriodMode: false,

    // アクション
    action: false,

    // バリデーション用 回答フラグ
    saveRegist: false,

    // タブ
    tabId: "",

    // formバリデーション
    formBaseValid: true,
    formAnsValid: true,
    formNoteValid: true,

    // file
    excelFile: null,
  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    this.branchName = this.$store.getters.getTargetBranchName;     // 編集する区別名をstoreから取得

    this.loadMrefrigerant();

    this.loadData();
  },

  mounted: async function () {},

  methods: {
    // 変更
    formChanged() {
      if(!this.loading) {
        this.$store.commit('setFormChanged', true);
      }
    },

    /** 冷媒一覧取得  */
    async loadMrefrigerant() {
      Debug.log("function[loadMrefrigerant]");
      this.loading = true;

      // DBからデータ取得
      try {
        const res = await Mrefrigerant.selectList();
        Debug.log(res.data);

        if (res.data) {
          // 調査対象年度
          this.refrigerantList = res.data;
          Debug.log(this.refrigerantList);
        }
      } catch (error) {
        Message.err(error, "冷媒一覧を取得できませんでした");
      }

      this.loading = false;
    },

    /** 表示更新  */
    async loadData() {
      Debug.log("function[loadData]");
      this.loading = true;
      this.surveyId = this.$store.getters.getTargetSurveyId; // 編集する冷媒調査IDをstoreから取得
      this.memberNo = this.$store.getters.getTargetMemberNo; // 編集する会員IDをstoreから取得
      this.branch = this.$store.getters.getTargetBranch;     // 編集する区別をstoreから取得

      // DBからデータ取得
      try {
        const res = await RefrigerantAnswer.getData(this.surveyId, this.memberNo, this.branch);
        Debug.log(res.data);

        if (res.data) {
          // 調査対象年度
          this.targetYear = res.data.targetYear;
          Debug.log(this.targetMonthData);

          // 事業所会員データ
          this.officeData = res.data.officeData;
          Debug.log(this.officeData);

          // 問い合わせ先データ
          if (res.data.contactData) {
            //this.contactData = Object.assign(this.contactData, res.data.contactData);
            this.contactData = res.data.contactData;
          }
          if (!this.contactData["volumeTotal"]) {
            this.contactData["volumeTotal"] = this.officeData["volume"]; // 初回のみ日冷倉協届出の所管容積を表示
          }
          this.contactData.branchName = this.branchName;  // 区別名を追加
          Debug.log(this.contactData);

          // 回答票データ
          const keys = Object.keys(this.surveyDatas[0]);
          for (let i = 0; i < 10; i++) {
            if (res.data.answerDetail[i]) {
              keys.forEach((key) => {
                this.surveyDatas[i][key] = res.data.answerDetail[i][key];
              });
            }
          }

          // 前年回答データ
          for(let i = 0 ; i < res.data.prevAnswerDetail.length ; i++) {
            this.prevSurveyDatas.splice(i, 1, res.data.prevAnswerDetail[i]);
          }

          // 追加の注意書き
          this.addNote = res.data.addNote;

          // 追加設問
          this.qaList = res.data.qaList;

          // 調査宛先
          this.dest = res.data.dest;

          Debug.log(this.surveyDatas);

          if(this.contactData.status == 0) this.show();
        }
      } catch (error) {
        Message.err(error, "冷媒調査回答を取得できませんでした");
      }

      this.$nextTick(() => {
        this.$store.commit('setFormChanged', false);
      });
      this.loading = false;
    },

    /** 数字のみ許可 */
    numOnlyInput(value, minus = false, decimal = false, decimalNum = 0) {
      let tmpValue = Util.numOnly(value, minus, decimal, decimalNum);
      return tmpValue;
    },

    /** computedの自動計算値を更新 */
    updateValue() {
      Debug.log("function[updateValue]");
      this.contactData.warehouseVolume = this.warehouseVolumeCalc;
      this.contactData.otherVolume = this.otherVolumeCalc;
    },

    /** 一時保存 */
    saveData() {
      Debug.log("function[saveData]");

      this.formBaseValid = this.$refs.formBase.validate();
      this.formAnsValid = this.$refs.formAns.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formAnsValid ||
            !this.formNoteValid ) {
        let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formAnsValid) msg.push("回答票");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("一時保存します。よろしいですか？")) return;

      this.action = true;
      this.contactData.status = 1; // statusを一時保存中に変更
      this.updateValue(); // computedの自動計算値を更新
      this.registData(); // DBに登録
    },
    /** 回答 */
    answerData() {
      Debug.log("function[answerData]");

      this.saveRegist = true;

      this.formBaseValid = this.$refs.formBase.validate();
      this.formAnsValid = this.$refs.formAns.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formAnsValid ||
            !this.formNoteValid ) {
        let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formAnsValid) msg.push("回答票");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        this.saveRegist = false;
        return;
      }

      if (!confirm("回答を確定します。よろしいですか？")) return;

      this.action = true;
      this.contactData.status = 3; // statusを確定済に変更
      this.updateValue(); // computedの自動計算値を更新
      this.registData(); // DBに登録
    },
    /** 取消 */
    cancel() {
      Debug.log("function[cancel]");

      if (!confirm("キャンセルします。よろしいですか？")) return;
      this.$router.back();
    },
    // 差戻し
    async sendBackData() {
      this.formBaseValid = this.$refs.formBase.validate();
      this.formAnsValid = this.$refs.formAns.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formAnsValid ||
            !this.formNoteValid ) {
        let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formAnsValid) msg.push("回答票");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("回答を保存して確定を解除します。解除後は事業所が回答を変更できるようになります。よろしいですか？")) return;

      this.action = true;
      this.contactData.status = 1; // statusを一時保存に変更
      this.updateValue(); // computedの自動計算値を更新
      this.registData();
    },

    /** DBに登録 */
    async registData() {
      this.loading = true;
      try {
        const res = await RefrigerantAnswer.setData(this.surveyDatas, this.surveyId, this.memberNo, this.contactData, this.branch, this.qaList);

        this.action = false;

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";

          if (Array.isArray(validationMsg)) {
            validationMsg.forEach((m) => (message += m + "\n"));
          } else {
            message = validationMsg;
          }

          alert(message);
          this.$router.back();
          this.loading = false;
          return;
        } else {
          let resMsg = '保存しました。';
          if(this.contactData.status == 1) {
            resMsg = '一時保存しました。';
          } else if(this.contactData.status == 3) {
            resMsg = '回答しました。';
          }
          alert(resMsg);

          this.$store.commit('setFormChanged', false);
          this.$router.back();
          this.loading = false;
        }
      } catch (error) {
        Message.err(error, this.contactData.status == 1 ? "一時保存できませんでした。" : "回答できませんでした。");
        this.loading = false;
      }
    },

    /** 回答受付期間の変更 */
    // キャンセル
    cancelAnsPeriod() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      //this.search();
      this.editAnsPeriodMode = false;
    },
    // 変更
    editAnsPeriod() {
      this.editAnsPeriodMode = true;
    },
    // 保存
    async registAnsPeriod() {
      if (!confirm("回答受付期間を保存します。よろしいですか？")) return;

      this.loading = true;
      try {
        const res = await RefrigerantDest.setAnsPeriod(this.dest);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }

      } catch (error) {
        Message.err(error, "保存できませんでした");
      }
      this.loading = false;
      this.editAnsPeriodMode = false;

      // データを再取得
      //this.search();
    },

    // ステータス変更
    async registStatus() {
      if(this.contactData.status == 3) {
        this.saveRegist = true;
      } else {
        this.saveRegist = false;
      }

      this.formBaseValid = this.$refs.formBase.validate();
      this.formAnsValid = this.$refs.formAns.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formAnsValid ||
            !this.formNoteValid ) {
        let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formAnsValid) msg.push("回答票");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        if(this.contactData.status == 3) {
          this.saveRegist = false;
        }

        // ステータスを元に戻して返却
        this.$nextTick(() => {
          this.contactData.status = this.oldStatus;
        });
        return;
      }

      if (!confirm("回答を保存します。よろしいですか？")) {
        // ステータスを元に戻して返却
        this.$nextTick(() => {
          this.contactData.status = this.oldStatus;
        });

        return;
      }

      this.updateValue(); // computedの自動計算値を更新
      this.registData(); // DBに登録
    },

    // 前年度入力値
    inputPrevData() {
      if (!confirm("前年度の入力値をコピーします。よろしいですか？")) return;

      // データの初期化
      this.surveyDatas = this.$options.data().surveyDatas;
      for(let i = 0 ; i < this.prevSurveyDatas.length ; i++) {
        // 前年度の回答データで上書きする
        const tmpData = {
          usage: this.prevSurveyDatas[i].usage,
          volume: this.prevSurveyDatas[i].volume,
          method: this.prevSurveyDatas[i].method,
          singleName: this.prevSurveyDatas[i].singleName,
          singleInit: this.prevSurveyDatas[i].singleInit,
          singleYear: this.prevSurveyDatas[i].singleYear,
          singlePrice: this.prevSurveyDatas[i].singlePrice,
          dualName: this.prevSurveyDatas[i].dualName,
          dualInit: this.prevSurveyDatas[i].dualInit,
          dualYear: this.prevSurveyDatas[i].dualYear,
          dualPrice: this.prevSurveyDatas[i].dualPrice,
          worker: this.prevSurveyDatas[i].worker,
          remarks: this.prevSurveyDatas[i].remarks,
        };
        this.surveyDatas.splice(i, 1, tmpData);
      }
    },

    /** モーダル表示用 */
    show () {
      Debug.log('function[show]');

      this.$modal.show('modal-dialog');
    },
    hide : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog');
    },

    /** タブ */
    setActiveTab(tabId) {
      Debug.log('function[setActiveTab]');
      this.tabId = tabId;
    },

    /** 回答票Excelインポート */
    showImport () {
      Debug.log('function[showImport]');

      this.$modal.show('import-modal-dialog');
    },
    hideImport : function () {
      Debug.log('function[hideImport]');
      this.$modal.hide('import-modal-dialog');
    },

    getExt(fileName) {
      var pos = fileName.lastIndexOf('.');
      if (pos === -1) return '';
      return fileName.slice(pos + 1);
    },

    importExcel() {
      const e = this.excelFile;
      Debug.log2('file', e);
      Debug.log2('ext', this.getExt(e.name));
      if(this.getExt(e.name) !== 'xlsx'){
        alert("xlsx形式のファイルを指定してください。");
        return;
      }

      if (!confirm("回答票Excelの入力値をコピーします。よろしいですか？")) return;

      var reader = new FileReader();
      reader.onload = function (e) {
        var unit8 = new Uint8Array(e.target.result);

        const workbook = XLSX.read(unit8, {type: "array"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet);

        Debug.log2('rows', rows);

        // ===== データコピー =====
        // 基礎情報
        this.$set(this.contactData, "responderName", this.getCellValue(sheet, 'D6', 'w'));            // 担当者名
        this.$set(this.contactData, "tel", this.getCellValue(sheet, 'D7', 'w'));                      // 電話
        this.$set(this.contactData, "email", this.getCellValue(sheet, 'D8', 'v'));                    // メール
        // お届け所管容積はExcelに入力がある場合のみコピー
        const tmpInputVolumeTotal = this.getCellValue(sheet, 'I6', 'v', true);
        if(typeof tmpInputVolumeTotal !== "undefined" && tmpInputVolumeTotal !== null) {
          this.$set(this.contactData, "volumeTotal", Util.orgRound(tmpInputVolumeTotal, 1));     // お届け所管容積
        }

        // 回答票
        let row = 16;
        for(let i=0; i<this.surveyDatas.length; i++) {
          let col = 1;

          this.$set(this.surveyDatas[i], 'usage', this.getRefrigerantUsage(sheet, this.getEncode_cell(col++, row)));      // 使用用途
          this.$set(this.surveyDatas[i], 'volume', Util.orgRound(
                                                    this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                    10));      // 対象所管容積
          this.$set(this.surveyDatas[i], 'method', this.getRefrigerantMethod(sheet, this.getEncode_cell(col++, row)));    // 冷却方式

          // 冷媒1
          this.$set(this.surveyDatas[i], 'singleName', this.getRefrigerantName(sheet, this.getEncode_cell(col++, row)));  // 冷媒名
          this.$set(this.surveyDatas[i], 'singleInit', Util.orgRound(
                                                        this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                        10));      // 初期充填量
          this.$set(this.surveyDatas[i], 'singleYear', Util.orgRound(
                                                        this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                        10));      // 年間充填量
          this.$set(this.surveyDatas[i], 'singlePrice', Util.orgRound(
                                                        this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                        1));      // 購入価格

          // 冷媒2
          if(this.surveyDatas[i].method == 1) {
            // 冷却方式が2元の場合
            this.$set(this.surveyDatas[i], 'dualName', this.getRefrigerantName(sheet, this.getEncode_cell(col++, row)));  // 冷媒名
            this.$set(this.surveyDatas[i], 'dualInit', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                          10));      // 初期充填量
            this.$set(this.surveyDatas[i], 'dualYear', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                          10));      // 年間充填量
            this.$set(this.surveyDatas[i], 'dualPrice', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                          1));      // 購入価格
          } else {
            this.$set(this.surveyDatas[i], 'dualName', null);      // 冷媒名
            this.$set(this.surveyDatas[i], 'dualInit', null);      // 初期充填量
            this.$set(this.surveyDatas[i], 'dualYear', null);      // 年間充填量
            this.$set(this.surveyDatas[i], 'dualPrice', null);     // 購入価格
            col++;
            col++;
            col++;
            col++;
          }

          this.$set(this.surveyDatas[i], 'worker', this.getRefrigerantWorker(sheet, this.getEncode_cell(col++, row)));    // 冷媒の充填実施者
          this.$set(this.surveyDatas[i], 'remarks', this.getCellValue(sheet, this.getEncode_cell(col++, row), 'w'));      // 記入欄
          
          row++;
        }

        // 備考
        this.$set(this.contactData, "ansRemarks", this.getCellValue(sheet, 'B50', 'w'));  // 備考

      }.bind(this);
      reader.readAsArrayBuffer(e);

      this.hideImport();
    },

    getRefrigerantUsage(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      tmpCell = (typeof tmpCell !== "undefined" && tmpCell !== null) ? tmpCell : ''
      let tmpVal = this.refrigerantUsage.find(function(v) { 
                      return v.text == tmpCell;
                    });

      if(typeof tmpVal !== "undefined" && tmpVal !== null) {
        return tmpVal.value;
      } else {
        return null;
      }
    },

    getRefrigerantMethod(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      tmpCell = (typeof tmpCell !== "undefined" && tmpCell !== null) ? tmpCell : ''
      let tmpVal = this.refrigerantMethod.find(function(v) { 
                      return v.text == tmpCell;
                    });

      if(typeof tmpVal !== "undefined" && tmpVal !== null) {
        return tmpVal.value;
      } else {
        return null;
      }
    },

    getRefrigerantName(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      tmpCell = (typeof tmpCell !== "undefined" && tmpCell !== null) ? tmpCell : ''
      let tmpVal = this.refrigerantList.find(function(v) { 
                      return v.text == tmpCell;
                    });

      if(typeof tmpVal !== "undefined" && tmpVal !== null) {
        return tmpVal.value;
      } else {
        return null;
      }
    },

    getRefrigerantWorker(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      tmpCell = (typeof tmpCell !== "undefined" && tmpCell !== null) ? tmpCell : ''
      let tmpVal = this.refrigerantWorker.find(function(v) { 
                      return v.text == tmpCell;
                    });

      if(typeof tmpVal !== "undefined" && tmpVal !== null) {
        return tmpVal.value;
      } else {
        return null;
      }
    },

  },

  watch: {
    'contactData.status': function(newValue, oldValue){
      // 一時保存、確定、確定解除で変更された場合は何もしない
      if(this.action) return;

      // プルダウンからステータスが変更された場合
      if( (typeof oldValue != 'undefined') && 
          ( (this.oldStatus == null) || (this.oldStatus != newValue) )) {
          this.oldStatus = oldValue;
          this.registStatus();
      }
    },

    'contactData.email': function(newValue){
      // メール欄に半角全角スペース入力不可
      const inputCheck = /\s+/g; // スペースの正規表現
      if(newValue != null)
      {
        this.$nextTick(() => {
          this.$set(this.contactData, 'email', newValue.replace(inputCheck, ''));
        });
      }
    },
  },

  computed: {
    /** 冷蔵庫・冷凍庫 計  */
    warehouseVolumeCalc() {
      let tmpWarehouseVolume = 0; // 用途と容積が変更されるたび計算結果リセット

      this.surveyDatas.forEach(function (data) {
        if (data.usage == 0 && data.volume != null) {
          tmpWarehouseVolume += parseFloat(data.volume);
        }
      });

      return Math.round(tmpWarehouseVolume);
    },

    /** 荷捌室 計  */
    cargoVolumeCalc() {
      let tmpCargoVolume = 0; // 用途と容積が変更されるたび計算結果リセット

      this.surveyDatas.forEach(function (data) {
        if (data.usage == 1 && data.volume != null) {
          tmpCargoVolume += parseFloat(data.volume);
        }
      });

      return Math.round(tmpCargoVolume);
    },

    /** 製氷 計  */
    iceVolumeCalc() {
      let tmpIceVolume = 0; // 用途と容積が変更されるたび計算結果リセット

      this.surveyDatas.forEach(function (data) {
        if (data.usage == 2 && data.volume != null) {
          tmpIceVolume += parseFloat(data.volume);
        }
      });

      return Math.round(tmpIceVolume);
    },

    /** 凍結 計  */
    frozenVolumeCalc() {
      let tmpFrozenVolume = 0; // 用途と容積が変更されるたび計算結果リセット

      this.surveyDatas.forEach(function (data) {
        if (data.usage == 3 && data.volume != null) {
          tmpFrozenVolume += parseFloat(data.volume);
        }
      });

      return Math.round(tmpFrozenVolume);
    },

    /** その他 計  */
    otherVolumeCalc() {
      let tmpOtherVolume = 0; // 用途と容積が変更されるたび計算結果リセット

      this.surveyDatas.forEach(function (data) {
        if (data.usage != 0 && 
            data.usage != 1 &&
            data.usage != 2 &&
            data.usage != 3 &&
            data.volume != null) {
          tmpOtherVolume += parseFloat(data.volume);
        }
      });

      return Math.round(tmpOtherVolume);
    },
  },
};
</script>

<style scoped>

.v-data-table {
  overflow-x: auto;
}

/** リスト装飾  */
/* 黒丸を表示しない  */
ul {
  list-style: none;
}

/** 冷媒テーブル */
/* .v-data-table >>> .v-data-table__wrapper table { */
  /* border: 4px solid #888888 !important; */
  /* width: 1920px; */
/* } */

.v-data-table >>> .v-data-table__wrapper table thead {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: white;
  z-index: 50;
}
/* .v-data-table >>> .v-data-table__wrapper table thead tr:first-of-type th { */
  /* border-top: 4px solid #888888 !important; */
/* } */
.v-data-table >>> .v-data-table__wrapper table thead tr:nth-of-type(2) th {
  border-bottom: 1px solid #888888 !important;
}

.v-data-table >>> .v-data-table__wrapper th {
  padding-left: 5px;
  padding-right: 5px;
}
.v-data-table >>> .v-data-table__wrapper td {
  padding-left: 5px;
  padding-right: 5px;
}

.v-data-table >>> .v-data-table__wrapper thead > tr:first-child > th:nth-of-type(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0px;
  background: white;
}
.v-data-table >>> .v-data-table__wrapper thead > tr:first-child > th:nth-of-type(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 180px;
  background: white;
}
.v-data-table >>> .v-data-table__wrapper thead > tr:first-child > th:nth-of-type(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 300px;
  background: white;
}

/** col1 */
/* .v-data-table >>> .v-data-table__wrapper thead > tr:first-child > th:nth-of-type(3) {
  border-right: 2px solid var(--v-primary-base) !important;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(3) {
  border-right: 2px solid var(--v-primary-base) !important;
} */
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0px;
  background: white;
  z-index: 49;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 180px;
  background: white;
  z-index: 49;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 300px;
  background: white;
  z-index: 49;
}

/** col2 */
.v-data-table >>> .v-data-table__wrapper thead > tr:first-child > th:nth-of-type(4) {
  border: 1px solid var(--v-primary-base) !important;
  background: var(--v-primary-base);
  color: #ffffff;
}
.v-data-table >>> .v-data-table__wrapper thead > tr:nth-of-type(2) > th:nth-of-type(1) {
  border-left: 4px solid var(--v-primary-base) !important;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(4) {
  border-left: 4px solid var(--v-primary-base) !important;
}
.v-data-table >>> .v-data-table__wrapper thead > tr:nth-of-type(2) > th:nth-of-type(4) {
  border-right: 2px solid #888888 !important;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(7) {
  border-right: 2px solid #888888 !important;
}

/** col3 */
.v-data-table >>> .v-data-table__wrapper thead > tr:first-child > th:nth-of-type(5) {
  border: 1px solid #ffc107 !important;
  background: #ffc107;
}
.v-data-table >>> .v-data-table__wrapper thead > tr:nth-of-type(2) > th:nth-of-type(5) {
  border-left: 2px solid #888888 !important;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(8) {
  border-left: 2px solid #888888 !important;
}
.v-data-table >>> .v-data-table__wrapper thead > tr:nth-of-type(2) > th:nth-of-type(8) {
  border-right: 2px solid #ffc107 !important;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(11) {
  border-right: 2px solid #ffc107 !important;
}

/** col4 */
.v-data-table >>> .v-data-table__wrapper thead > tr:first-child > th:nth-of-type(6) {
  border-left: 2px solid #ffc107 !important;
}
.v-data-table >>> .v-data-table__wrapper tbody > tr > td:nth-of-type(12) {
  border-left: 2px solid #ffc107 !important;
}

/** 冷媒の充填実施者 */
.v-data-table >>> .v-data-table__wrapper th.worker {
  background: var(--v-attention-base);
}
.v-data-table >>> .v-data-table__wrapper td.worker {
  background: #fff9c4;
}

/** 注意書き */
.add-note {
  white-space: pre-line;
}

/** タブ */
.v-slide-group__wrapper {
  height: 25px !important;
}
.v-tabs >>> .v-tabs-bar__content {
  height: 25px !important;
}
.v-tabs >>> .v-tabs-bar {
  height: 25px !important;
}
.v-tabs >>> .v-slide-group__wrapper {
  border-bottom: 1px solid var(--v-primary-base);
}

.v-tabs >>> .v-tabs-bar__content .v-tab {
  border-top: 1px solid var(--v-primary-base);
  border-right: 1px solid var(--v-primary-base);
  border-bottom: 1px solid var(--v-primary-base);
}
.v-tabs >>> .v-tabs-bar__content .v-tab:first-of-type {
  border-left: 1px solid var(--v-primary-base);
}

.v-tab {
  height: 25px !important;
  font-weight: bold;
}
.v-tab:hover {
  background-color: var(--v-info_hover-base) !important;
}
.v-tab--active {
  background-color: var(--v-info-base);
}
.v-tab--active:hover {
  background-color: var(--v-info-base);
}



.tab-width-200 {
  width: 200px;
  min-width: 200px;
}

.tab-item {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 180px);
}

.annotation {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.input-prev-data-button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ref-table-wrap {
  height: calc(100vh - 600px);
  overflow:auto
}

/** モーダル */
.modal-wrap {
  height: 100%;
}
.modal-header {
  height: 10%;
}
.modal-header h2 {
  align-items: center;
  display: inline-flex;
}
.modal-body {
  /* overflow-y: scroll; */
  height: 90%;
}
</style>
