import Axios from "axios";
import token from "./setToken";

export default {
  async getOfficeList(companyId, targetYear) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/Electricity/" + companyId + "/" + targetYear + "/", token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査一覧を取得できません[getOfficeList]");
      throw error;
    }
  },

  async sendAnswer(companyId) {
    try {
      // await token.checkToken();
      const res = await Axios.post("/api/Electricity/" + companyId, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("回答を送信できません[sendAnswer]");
      throw error;
    }
  },

  async setElectricityData(mailData, mailDestArray, electricityData, attachment, mode) {
 
    let params = new FormData();
    params.append('mailData', JSON.stringify(mailData));
    params.append('mailDestArray', JSON.stringify(mailDestArray));
    params.append('electricityData', JSON.stringify(electricityData));
    // ファイル
    for(let i=0; i < attachment.length; i++) {
      params.append('attachment['+i+']', attachment[i]);
    }
    params.append('mode', mode);
 
    try {
      // await token.checkToken();
      const res = await Axios.post('/api/Electricity', params, token.setTokenToHeader());
      return res;
 
    } catch (error) {
      console.error("電力調査を設定できません");
      throw error;
    }
  }, 
 
  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));
 
    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricityList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査を取得できません[search]");
      throw error;
    }
  },
 
  async getSurveyAnsStatus(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricityAnsStatus", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査を取得できません[getSurveyAnsStatus]");
      throw error;
    }
  },

  /** 調査取得 */
  async getExistSurvey(targetYear) {
    let params = new URLSearchParams();
    params.append('targetYear', JSON.stringify(targetYear));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricityExistSurvey", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査を取得できません[getExistSurvey]");
      throw error;
    }
  },

  /** 電力調査集計 */
  async sumElectricityDB(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/ElectricitySumDB", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("電力調査集計を作成できません[sumElectricityDB]");
      throw error;
    }
  },

};
