<template>
  <v-container>
    <AlertSnackbar v-if="attribute == 0 && contactData.status < 3" :disp="!isBetweenDate(dest.ans_period_from, dest.and_period_to)" color="warning" message="回答受付期間外のため、確定できません"></AlertSnackbar>
    <AlertSnackbar v-else :disp="contactData.status >= 3" color="accent" :message="contactData.status == 3 ? '回答は確定済みです' : 
                                                                                    contactData.status == 4 ? '回答は確認中です' :
                                                                                    contactData.status == 5 ? '回答は保留中です' : '回答は確認済みです'"></AlertSnackbar>
    <v-row>
      <v-col>
        <div class="d-flex">
        <h3>{{officeData.companyName}}様 {{officeData.officeName}} {{branchName}} 電力実態調査</h3><v-icon class="ml-1" v-on:click="show()">mdi-help-circle-outline</v-icon>
      </div>
      </v-col>
      <v-col class="text-center">
        <div class="d-flex justify-end">
          <v-btn class="btn_font primary mr-2" width="150" v-on:click="showImport()" :disabled="loading || ((attribute == 0) && (contactData.status >= 3))" >回答票インポート</v-btn>
          <v-btn class="btn_font primary mr-2" width="120" v-on:click="saveData()" :disabled="loading || ((attribute == 0) && (contactData.status >= 3))" >一時保存</v-btn>
          <v-btn 
            class="btn_font primary mr-2" 
            width="120" 
            v-on:click="answerData()" 
            :disabled="loading || ( (attribute == 0) && ((contactData.status >= 3) || !isBetweenDate(dest.ans_period_from, dest.and_period_to)) )" >確定
          </v-btn>
          <v-btn v-if="attribute != 0 && contactData.status >= 3"
            class="btn_font primary mr-2" width="120" v-on:click="sendBackData()" :disabled="loading" >確定解除</v-btn>
          <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading">キャンセル</v-btn>
        </div>
      </v-col>
    </v-row>
    
    <v-row class="mt-0">
      <v-col class="pt-1">
        <v-tabs class="" :slider-size=0 v-model="tabId">
          <v-tab href="#tab-base" class="tab-width-200">基礎情報<v-icon v-if="!this.formBaseValid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-main10" class="tab-width-200">【１】10大電力会社<v-icon v-if="!this.formMain10Valid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-self" class="tab-width-200">【２】自家発電<v-icon v-if="!this.formSelfValid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-new" class="tab-width-200">【３】新電力会社<v-icon v-if="!this.formNewValid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-note" class="tab-width-200">備考<v-icon v-if="!this.formNoteValid" :small="true" color="warning">mdi-alert</v-icon></v-tab>

          <!-- 基礎情報 -->
          <v-tab-item value="tab-base" class="tab-item" :transition="false" eager>
            <v-form ref="formBase" v-model="validBase" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                  <v-alert type="info" border="left" colored-border elevation="1" class="pt-1 pb-1 mb-0">
                    <ul>
                      <li><span class="">色のついた</span>セルにご記入ください。</li>
                      <li>調査の対象期間は{{ contactData.targetMonthFrom }}～{{ contactData.targetMonthTo }}です。金額は消費税を含みます。</li>
                      <span class="add-note">{{ addNote }}</span>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col class="d-flex align-center" cols="4" sm="4" md="4" lg="4">
                  <DatePicker
                    label="回答受付期間 開始"
                    v-model="dest.ans_period_from"
                    format="YYYY/MM/DD"
                    selectType="date"
                    :readonly="attribute == 0 || !editAnsPeriodMode"
                    :rules="[checkStartEndDate(dest.ans_period_from, dest.and_period_to)]"
                    :clearable="false"
                    :loading="loading"
                  ></DatePicker>
                  ～
                  <DatePicker
                    label="回答受付期間 終了"
                    v-model="dest.and_period_to"
                    format="YYYY/MM/DD"
                    selectType="date"
                    :readonly="attribute == 0 || !editAnsPeriodMode"
                    :rules="[checkStartEndDate(dest.ans_period_from, dest.and_period_to)]"
                    :clearable="false"
                    :loading="loading"
                  ></DatePicker>
                </v-col>
                <v-col v-if="attribute != 0" cols="4">
                  <v-btn v-if="!editAnsPeriodMode" class="btn_font primary" width="120" v-on:click="editAnsPeriod()" :disabled="loading" >受付期間変更</v-btn>
                  <v-btn v-else class="btn_font primary" width="120" v-on:click="registAnsPeriod()" :disabled="loading" >保存</v-btn>
                  <v-btn v-if="editAnsPeriodMode" class="ml-5 btn_font primary" width="120" v-on:click="cancelAnsPeriod()" :disabled="loading" >キャンセル</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-select
                    label="ステータス"
                    v-model="contactData.status"
                    :items="ansStatus"
                    dense
                    :readonly="attribute == 0"
                    :clearable="false"
                    :loading="loading"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" sm="2" md="2" lg="2">
                  <v-text-field label="会員番号" v-model="officeData.memberNo" readonly :loading="loading" hide-details dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field label="会社名" v-model="officeData.companyName" readonly :loading="loading" hide-details dense></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field label="事業所名" v-model="officeData.officeName" readonly :loading="loading" hide-details dense></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field label="区別(棟)" v-model="contactData.branchName" :readonly="attribute == 0 && contactData.status >= 3" :loading="loading" hide-details dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field label="所在地" v-model="officeData.addressBldg" readonly :loading="loading" hide-details dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h4>基礎情報 ※{{ asOfMonth | formatDate('YYYY年M月') }}末時点をご記入ください。<span class="blue-text annotation" v-on:click="show()"> 注①</span></h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <NumberTextField
                    label="所管容積合計[㎥(立方メートル)]"
                    v-model="contactData.volumeTotal"
                    :readonly="attribute == 0 && contactData.status >= 3"
                    suffix="㎥"
                    :loading="loading"
                    :orgRoundStep="1"
                    :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                    background-color="#ffffcc"
                    @change="formChanged"
                  ></NumberTextField>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <NumberTextField 
                    label="製氷年間生産量[ﾄﾝ/年]" 
                    v-model="contactData.iceVolume" 
                    :readonly="attribute == 0 && contactData.status >= 3"
                    suffix="ﾄﾝ/年"
                    :loading="loading"
                    :orgRoundStep="1"
                    :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                    :zeroIsNull="true"
                    background-color="#ffffcc"
                    @change="formChanged"
                  ></NumberTextField>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <NumberTextField 
                    label="凍結年間生産量[ﾄﾝ/年]" 
                    v-model="contactData.frozenVolume" 
                    :readonly="attribute == 0 && contactData.status >= 3"
                    suffix="ﾄﾝ/年"
                    :loading="loading"
                    :orgRoundStep="1"
                    :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                    :zeroIsNull="true"
                    background-color="#ffffcc"
                    @change="formChanged"
                  ></NumberTextField>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <h4>記入内容に関する問い合わせ先</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field
                    label="担当者名" 
                    v-model="contactData.responderName" 
                    :readonly="attribute == 0 && contactData.status >= 3"
                    :loading="loading"
                    :rules="[maxLength(50)]" 
                    :maxlength="50"
                    dense
                    background-color="#ffffcc"
                    @change="formChanged"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field
                    label="電話" 
                    v-model="contactData.tel" 
                    :readonly="attribute == 0 && contactData.status >= 3"
                    :loading="loading"
                    :rules="[maxLength(50)]" 
                    :maxlength="50"
                    dense
                    background-color="#ffffcc"
                    @change="formChanged"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3">
                  <v-text-field
                    label="メール" 
                    v-model="contactData.email" 
                    :readonly="attribute == 0 && contactData.status >= 3"
                    :rules="[validEmail, maxLength(100)]"
                    :loading="loading"
                    :maxlength="100"
                    :counter="100"
                    dense
                    background-color="#ffffcc"
                    @change="formChanged"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="justify-end mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-main10')" :disabled="loading">次へ<v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <!-- 10大電力 -->
          <v-tab-item value="tab-main10" class="tab-item" :transition="false" eager>
            <v-form ref="formMain10" v-model="validMain10" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                  <v-alert type="info" border="left" colored-border elevation="1" class="pt-1 pb-1 mb-0">
                    <ul>
                      <li><span class="yellow-back border">色のついた</span>セルにご記入ください。</li>
                      <li>調査の対象期間は{{ contactData.targetMonthFrom }}～{{ contactData.targetMonthTo }}です。金額は消費税を含みます。</li>
                      <span class="add-note">{{ addNote }}</span>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row class="mt-n1">
                <v-col>
                  <h3>【１】１０大電力会社</h3>
                  <div class="text-error">１０大電力会社以外（新電力会社）は、「【３】新電力会社」に記入してください。<br />途中で契約電力会社が変わった場合は、変更月以降の電力会社名に入力してください。</div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col style="overflow: auto" class="paddingToMargin">
                  <div class="main10-table-wrap">
                    <table>
                      <colgroup>
                        <col style="width:50px;">
                        <col style="width:200px;">
                        <col style="width:50px;">
                        <col v-for="(monthly) in main10ElecCo" :key="monthly.index" style="width:150px;">
                        <col style="width:150px;">
                        <col style="width:150px;">
                      </colgroup>
                      <thead>
                        <tr>
                          <th class="text-center" colspan="2" rowspan="2">入力項目</th>
                          <th class="text-center" rowspan="2">単位</th>
                          <th class="text-center" colspan="9">{{ ansTargetYear | formatDate('YYYY年') }} ({{ansTargetYear | formatJP({year:'numeric'})}})</th>
                          <th class="text-center" colspan="3">{{ ansNextYear | formatDate('YYYY年') }} ({{ansNextYear | formatJP({year:'numeric'})}})</th>
                          <th rowspan="2">年間合計</th>
                          <th rowspan="2">前年乖離幅</th>
                        </tr>
                        <tr>
                          <th class="text-center">4月</th>
                          <th class="text-center">5月</th>
                          <th class="text-center">6月</th>
                          <th class="text-center">7月</th>
                          <th class="text-center">8月</th>
                          <th class="text-center">9月</th>
                          <th class="text-center">10月</th>
                          <th class="text-center">11月</th>
                          <th class="text-center">12月</th>
                          <th class="text-center">1月</th>
                          <th class="text-center">2月</th>
                          <th class="text-center">3月</th>
                        </tr>
                      </thead>
                      <!-- ローディング -->
                      <tbody v-if="loading">
                        <tr>
                          <td colspan="17">
                            <v-progress-linear
                              indeterminate
                              :active="loading"
                            ></v-progress-linear>
                          </td>
                        </tr>
                      </tbody>
                      <!-- テーブル -->
                      <tbody v-else>
                        <tr>
                          <th class="fixed-item-1"></th>
                          <th class="fixed-item-2">１０大電力会社名<span class="blue-text annotation" v-on:click="show()"> 注②</span></th>
                          <th class="fixed-item-3">-</th>
                          <td v-for="(monthly, index) in main10ElecCo" :key="monthly.index" :class="setBackColor('supplier', index, main10ElecCo)">
                            <v-select 
                              v-model="monthly.supplier" 
                              item-text="text" 
                              item-value="value" 
                              :items="electricityElecCoName" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[requiredIf_select(() => (saveRegist) && (newElecCo[index].newSupplierName == null || newElecCo[index].newSupplierName.trim().length <= 0))]"
                              @input="autoInsert('supplier', index, main10ElecCo);
                                      $refs.formMain10.validate();
                                      $refs.formNew.validate();"
                              @change="formChanged"
                            ></v-select>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical" rowspan="4">請求項目</th>
                          <th class="fixed-item-2">契約種別<span class="blue-text annotation" v-on:click="show()"> 注③</span></th>
                          <th class="fixed-item-3">-</th>
                          <td v-for="(monthly, index) in main10ElecCo" :key="monthly.index" :class="setBackColor('plan', index, main10ElecCo)">
                            <v-text-field 
                            v-model="monthly.plan" 
                            v-on:input="autoInsert('plan', index, main10ElecCo)"
                            :readonly="attribute == 0 && contactData.status >= 3"
                            :rules="[requiredIf_ns(() => (saveRegist) && (monthly.supplier != null)), maxLength(50)]"
                            :maxlength="50"
                            @change="formChanged"
                            ></v-text-field>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">契約電力</th>
                          <th class="fixed-item-3">kw</th>
                          <td v-for="(monthly, index) in main10ElecCo" :key="monthly.index" class="yellow-back autoInsertRow">
                            <NumberTextField
                              v-model="monthly.contract"
                              v-on:input="autoInsert('contract', index, main10ElecCo)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">使用電力量</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField
                              v-model="monthly.energyUse" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="energyUseCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(energyUseCalc, prevEnergyUseCalc)" readonly suffix="%" :class="diffPercentage(energyUseCalc, prevEnergyUseCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">蓄熱電力量<span class="blue-text annotation" v-on:click="show()"> 注④</span></th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyStorage" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[requiredIf_ns(() => (saveRegist) && (!isEmptyCostStorage(main10ElecCo)) && (isEmptyEnergyStorage(main10ElecCo))), maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              @input="$refs.formMain10.validate()"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="energyStorageCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(energyStorageCalc, prevEnergyStorageCalc)" readonly suffix="%" :class="diffPercentage(energyStorageCalc, prevEnergyStorageCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical" rowspan="5"><span class="blue-text annotation" v-on:click="show()">注⑤ </span>使用電力量内訳</th>
                          <th class="fixed-item-2">冷蔵電力量(荷捌室含む)</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyCold" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="energyColdCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(energyColdCalc, prevEnergyColdCalc)" readonly suffix="%" :class="diffPercentage(energyColdCalc, prevEnergyColdCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">事務棟電力量</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyOffice" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="energyOfficeCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(energyOfficeCalc, prevEnergyOfficeCalc)" readonly suffix="%" :class="diffPercentage(energyOfficeCalc, prevEnergyOfficeCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">製氷電力量<span class="blue-text annotation" v-on:click="show()"> 注⑥</span></th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="isEmpty(contactData.iceVolume) ? 'gray-back' : isEmptyEnergyIce(main10ElecCo) ? 'red-back' : 'yellow-back'">
                            <NumberTextField 
                              v-model="monthly.energyIce" 
                              :disabled="isEmpty(contactData.iceVolume)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="energyIceCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(energyIceCalc, prevEnergyIceCalc)" readonly suffix="%" :class="diffPercentage(energyIceCalc, prevEnergyIceCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">凍結電力量<span class="blue-text annotation" v-on:click="show()"> 注⑦</span></th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="isEmpty(contactData.frozenVolume) ? 'gray-back' : isEmptyEnergyFrozen(main10ElecCo) ? 'red-back' : 'yellow-back'">
                            <NumberTextField 
                              v-model="monthly.energyFrozen"  
                              :disabled="isEmpty(contactData.frozenVolume)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="energyFrozenCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(energyFrozenCalc, prevEnergyFrozenCalc)" readonly suffix="%" :class="diffPercentage(energyFrozenCalc, prevEnergyFrozenCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">その他電力量</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyOther" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="energyOtherCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(energyOtherCalc, prevEnergyOtherCalc)" readonly suffix="%" :class="diffPercentage(energyOtherCalc, prevEnergyOtherCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical" rowspan="8"><span class="blue-text annotation" v-on:click="show()">注⑧ </span>請求書項目</th>
                          <th class="fixed-item-2">基本料金<span class="blue-text annotation" v-on:click="show()"> 注⑨</span></th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costBase" 
                              :class="monthly.costBase < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]" 
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costBaseCalc" :class="costBaseCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costBaseCalc, prevCostBaseCalc)" readonly suffix="%" :class="diffPercentage(costBaseCalc, prevCostBaseCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">電力量料金</th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costEnergy" 
                              :class="monthly.costEnergy < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costEnergyCalc" :class="costEnergyCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costEnergyCalc, prevCostEnergyCalc)" readonly suffix="%" :class="diffPercentage(costEnergyCalc, prevCostEnergyCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">燃料費調整額</th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costFuel" 
                              :class="monthly.costFuel < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costFuelCalc" :class="costFuelCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costFuelCalc, prevCostFuelCalc)" readonly suffix="%" :class="diffPercentage(costFuelCalc, prevCostFuelCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">再エネ賦課金等<span class="blue-text annotation" v-on:click="show()"> 注⑩</span></th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costReEnergy" 
                              :class="monthly.costReEnergy < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costReEnergyCalc" :class="costReEnergyCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costReEnergyCalc, prevCostReEnergyCalc)" readonly suffix="%" :class="diffPercentage(costReEnergyCalc, prevCostReEnergyCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">
                            <v-text-field 
                              v-model="otherNames.costOther1Name" 
                              label="項目入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.costOther1Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.costOther1" 
                              :class="monthly.costOther1 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.costOther1Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costOther1Calc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costOther1Calc, prevCostOther1Calc)" readonly suffix="%" :class="diffPercentage(costOther1Calc, prevCostOther1Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">
                            <v-text-field 
                              v-model="otherNames.costOther2Name" 
                              label="項目入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.costOther2Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.costOther2" 
                              :class="monthly.costOther2 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.costOther2Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costOther2Calc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costOther2Calc, prevCostOther2Calc)" readonly suffix="%" :class="diffPercentage(costOther2Calc, prevCostOther2Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">
                            <v-text-field 
                              v-model="otherNames.costOther3Name" 
                              label="項目入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.costOther3Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.costOther3" 
                              :class="monthly.costOther3 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.costOther3Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costOther3Calc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costOther3Calc, prevCostOther3Calc)" readonly suffix="%" :class="diffPercentage(costOther3Calc, prevCostOther3Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 green-text">賦課金減免の有無<span class="blue-text annotation" v-on:click="show()"> 注⑪</span></th>
                          <th class="fixed-item-3">-</th>
                          <td v-for="(monthly, index) in main10ElecCo" :key="monthly.index" class="yellow-back autoInsertRow">
                            <v-select
                              v-model="monthly.exemption"
                              item-text="text" 
                              item-value="value" 
                              :items="electricityExemption" 
                              v-on:input="autoInsert('exemption', index, main10ElecCo)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[requiredIf_select(() => (saveRegist) && (monthly.supplier != null))]"
                              @change="formChanged"
                            ></v-select>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical text-error" rowspan="6"><span class="blue-text annotation" v-on:click="show()">注⑫ </span>割引額</th>
                          <th class="fixed-item-2 text-error">産業用蓄熱調整契約<span class="blue-text annotation" v-on:click="show()"> 注④</span></th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="(!isEmptyEnergyStorage(main10ElecCo) && isEmpty(monthly.costStorage)) ? 'red-back' : 'yellow-back'">
                            <NumberTextField 
                              v-model="monthly.costStorage" 
                              :class="monthly.costStorage < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[requiredIf_ns(() => (saveRegist) && (!isEmptyEnergyStorage(main10ElecCo)) && (isEmptyCostStorage(main10ElecCo))), maxValLocaleJP(0), minValLocaleJP(-999999999.99)]"
                              @input="$refs.formMain10.validate()"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="costStorageCalc" :class="costStorageCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(costStorageCalc, prevCostStorageCalc)" readonly suffix="%" :class="diffPercentage(costStorageCalc, prevCostStorageCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.discountOther1Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.discountOther1Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther1" 
                              :class="monthly.discountOther1 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.discountOther1Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="discountOther1Calc" :class="discountOther1Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(discountOther1Calc, prevDiscountOther1Calc)" readonly suffix="%" :class="diffPercentage(discountOther1Calc, prevDiscountOther1Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.discountOther2Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.discountOther2Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther2" 
                              :class="monthly.discountOther2 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.discountOther2Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="discountOther2Calc" :class="discountOther2Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(discountOther2Calc, prevDiscountOther2Calc)" readonly suffix="%" :class="diffPercentage(discountOther2Calc, prevDiscountOther2Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.discountOther3Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.discountOther3Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther3" 
                              :class="monthly.discountOther3 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.discountOther3Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="discountOther3Calc" :class="discountOther3Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(discountOther3Calc, prevDiscountOther3Calc)" readonly suffix="%" :class="diffPercentage(discountOther3Calc, prevDiscountOther3Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.discountOther4Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.discountOther4Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther4" 
                              :class="monthly.discountOther4 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.discountOther4Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="discountOther4Calc" :class="discountOther4Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(discountOther4Calc, prevDiscountOther4Calc)" readonly suffix="%" :class="diffPercentage(discountOther4Calc, prevDiscountOther4Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.discountOther5Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"  
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="otherNames.discountOther5Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther5" 
                              :class="monthly.discountOther5 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.discountOther5Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="discountOther5Calc" :class="discountOther5Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(discountOther5Calc, prevDiscountOther5Calc)" readonly suffix="%" :class="diffPercentage(discountOther5Calc, prevDiscountOther5Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">支払電力料金(請求書より)<span class="blue-text annotation" v-on:click="show()"> 注⑬</span></th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.payment" 
                              :class="monthly.payment < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(999999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="paymentCalc" :class="paymentCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(paymentCalc, prevPaymentCalc)" readonly suffix="%" :class="diffPercentage(paymentCalc, prevPaymentCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">基本料金÷契約電力kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" width="150" :class="costBaseContract(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="costBaseContract(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">電力量料金÷使用電力量kwh</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="costEnergyEnergyUse(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="costEnergyEnergyUse(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">使用電力量当り再エネ賦課金</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="costReEnergyEnergyUse(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="costReEnergyEnergyUse(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">項目合計金額と支払料金との差</th>
                          <td v-for="monthly in main10ElecCo" :key="monthly.index" :class="paymentDiff(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="paymentDiff(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>

              <v-row class="justify-space-between mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-base')" :disabled="loading" ><v-icon>mdi-chevron-left</v-icon>前へ</v-btn>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-self')" :disabled="loading">次へ<v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <!-- 自家発電 -->
          <v-tab-item value="tab-self" class="tab-item" :transition="false" eager>
            <v-form ref="formSelf" v-model="validSelf" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                  <v-alert type="info" border="left" colored-border elevation="1" class="pt-1 pb-1 mb-0">
                    <ul>
                      <li><span class="yellow-back border">色のついた</span>セルにご記入ください。</li>
                      <li>調査の対象期間は{{ contactData.targetMonthFrom }}～{{ contactData.targetMonthTo }}です。金額は消費税を含みます。</li>
                      <span class="add-note">{{ addNote }}</span>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row class="mt-n1">
                <v-col>
                  <h3>【２】自家発電</h3>
                  <div class="text-error">自家発電量は自署消費量（kwh）を入力してください</div>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col style="overflow: auto" class="paddingToMargin">
                  <table>
                    <colgroup>
                      <col style="width:50px;">
                      <col style="width:200px;">
                      <col style="width:50px;">
                      <col v-for="(monthly) in main10ElecCo" :key="monthly.index" style="width:150px;">
                      <col style="width:300px;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th class="text-center" colspan="2" rowspan="2">入力項目</th>
                        <th class="text-center" rowspan="2">単位</th>
                        <th class="text-center" colspan="9">{{ ansTargetYear | formatDate('YYYY年') }} ({{ansTargetYear | formatJP({year:'numeric'})}})</th>
                        <th class="text-center" colspan="3">{{ ansNextYear | formatDate('YYYY年') }} ({{ansNextYear | formatJP({year:'numeric'})}})</th>
                        <th rowspan="2">年間合計</th>
                      </tr>
                      <tr>
                        <th class="text-center">4月</th>
                        <th class="text-center">5月</th>
                        <th class="text-center">6月</th>
                        <th class="text-center">7月</th>
                        <th class="text-center">8月</th>
                        <th class="text-center">9月</th>
                        <th class="text-center">10月</th>
                        <th class="text-center">11月</th>
                        <th class="text-center">12月</th>
                        <th class="text-center">1月</th>
                        <th class="text-center">2月</th>
                        <th class="text-center">3月</th>
                      </tr>
                    </thead>
                    <!-- ローディング -->
                    <tbody v-if="loading">
                      <tr>
                        <td colspan="17">
                          <v-progress-linear
                            indeterminate
                            :active="loading"
                          ></v-progress-linear>
                        </td>
                      </tr>
                    </tbody>
                    <!-- テーブル -->
                    <tbody v-else class="selfTbody">
                      <tr>
                        <th class="fixed-item-1 vertical" rowspan="3">自家発電量</th>
                        <th class="fixed-item-2">
                          <v-select v-model="otherNames.selfGeneration1Select" item-text="text" item-value="value" :items="electricitySelfGeneration" :readonly="attribute == 0 && contactData.status >= 3" @change="formChanged"></v-select>
                        </th>
                        <th class="fixed-item-3">kwh</th>
                        <td v-for="monthly in selfGeneration" :key="monthly.index" :class="otherNames.selfGeneration1Select ? 'yellow-back' : 'gray-back'">
                          <NumberTextField 
                            v-model="monthly.selfGeneration1" 
                            :class="monthly.selfGeneration1 < 0 ? 'text-error' : ''" 
                            :disabled="otherNames.selfGeneration1Select ? false : true" 
                            :readonly="attribute == 0 && contactData.status >= 3"
                            :orgRoundStep="1"
                            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                            :changeEvt="formChanged"
                          ></NumberTextField>
                        </td>
                        <td>
                          <NumberTextField v-model="selfGeneration1Calc" :class="selfGeneration1Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                        </td>
                      </tr>
                      <tr>
                        <th class="fixed-item-2">
                          <v-select v-model="otherNames.selfGeneration2Select" item-text="text" item-value="value" :items="electricitySelfGeneration" :readonly="attribute == 0 && contactData.status >= 3" @change="formChanged"></v-select>
                        </th>
                        <th class="fixed-item-3">kwh</th>
                        <td v-for="monthly in selfGeneration" :key="monthly.index" :class="otherNames.selfGeneration2Select ? 'yellow-back' : 'gray-back'">
                          <NumberTextField 
                            v-model="monthly.selfGeneration2" 
                            :class="monthly.selfGeneration2 < 0 ? 'text-error' : ''" 
                            :disabled="otherNames.selfGeneration2Select ? false : true" 
                            :readonly="attribute == 0 && contactData.status >= 3"
                            :orgRoundStep="1"
                            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                            :changeEvt="formChanged"
                          ></NumberTextField>
                        </td>
                        <td>
                          <NumberTextField v-model="selfGeneration2Calc" :class="selfGeneration2Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                        </td>
                      </tr>
                      <tr>
                        <th class="fixed-item-2">
                          <v-select v-model="otherNames.selfGeneration3Select" item-text="text" item-value="value" :items="electricitySelfGeneration" :readonly="attribute == 0 && contactData.status >= 3" @change="formChanged"></v-select>
                        </th>
                        <th class="fixed-item-3">kwh</th>
                        <td v-for="monthly in selfGeneration" :key="monthly.index" :class="otherNames.selfGeneration3Select ? 'yellow-back' : 'gray-back'">
                          <NumberTextField 
                            v-model="monthly.selfGeneration3" 
                            :class="monthly.selfGeneration3 < 0 ? 'text-error' : ''" 
                            :disabled="otherNames.selfGeneration3Select ? false : true" 
                            :readonly="attribute == 0 && contactData.status >= 3"
                            :orgRoundStep="1"
                            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                            :changeEvt="formChanged"
                          ></NumberTextField>
                        </td>
                        <td>
                          <NumberTextField v-model="selfGeneration3Calc" :class="selfGeneration3Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>

              <v-row class="justify-space-between mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-main10')" :disabled="loading" ><v-icon>mdi-chevron-left</v-icon>前へ</v-btn>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-new')" :disabled="loading">次へ<v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <!-- 新電力 -->
          <v-tab-item value="tab-new" class="tab-item" :transition="false" eager>
            <v-form ref="formNew" v-model="validNew" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                  <v-alert type="info" border="left" colored-border elevation="1" class="pt-1 pb-1 mb-0">
                    <ul>
                      <li><span class="yellow-back border">色のついた</span>セルにご記入ください。</li>
                      <li>調査の対象期間は{{ contactData.targetMonthFrom }}～{{ contactData.targetMonthTo }}です。金額は消費税を含みます。</li>
                      <span class="add-note">{{ addNote }}</span>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row class="mt-n1">
                <v-col>
                  <h3>【３】新電力会社</h3>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col style="overflow: auto" class="paddingToMargin">
                  <div class="new-table-wrap">
                    <table>
                      <colgroup>
                        <col style="width:50px;">
                        <col style="width:200px;">
                        <col style="width:50px;">
                        <col v-for="(monthly) in main10ElecCo" :key="monthly.index" style="width:150px;">
                        <col style="width:150px;">
                        <col style="width:150px;">
                      </colgroup>
                      <thead>
                        <tr>
                          <th class="text-center" colspan="2" rowspan="2">入力項目</th>
                          <th class="text-center" rowspan="2">単位</th>
                          <th class="text-center" colspan="9">{{ ansTargetYear | formatDate('YYYY年') }} ({{ansTargetYear | formatJP({year:'numeric'})}})</th>
                          <th class="text-center" colspan="3">{{ ansNextYear | formatDate('YYYY年') }} ({{ansNextYear | formatJP({year:'numeric'})}})</th>
                          <th rowspan="2">年間合計</th>
                          <th rowspan="2">前年乖離幅</th>
                        </tr>
                        <tr>
                          <th class="text-center">4月</th>
                          <th class="text-center">5月</th>
                          <th class="text-center">6月</th>
                          <th class="text-center">7月</th>
                          <th class="text-center">8月</th>
                          <th class="text-center">9月</th>
                          <th class="text-center">10月</th>
                          <th class="text-center">11月</th>
                          <th class="text-center">12月</th>
                          <th class="text-center">1月</th>
                          <th class="text-center">2月</th>
                          <th class="text-center">3月</th>
                        </tr>
                      </thead>
                      <!-- ローディング -->
                      <tbody v-if="loading">
                        <tr>
                          <td colspan="17">
                            <v-progress-linear
                              indeterminate
                              :active="loading"
                            ></v-progress-linear>
                          </td>
                        </tr>
                      </tbody>
                      <!-- テーブル -->
                      <tbody v-else>
                        <tr>
                          <th class="fixed-item-1"></th>
                          <th class="fixed-item-2">新電力会社名<span class="blue-text annotation" v-on:click="show()"> 注②</span></th>
                          <th class="fixed-item-3">-</th>
                          <td v-for="(monthly, index) in newElecCo" :key="monthly.index" :class="setBackColor('newSupplierName', index, newElecCo)">
                            <v-text-field
                              v-model="monthly.newSupplierName" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[requiredIf_ns(() => (saveRegist) && (main10ElecCo[index].supplier == null))]"
                              @input="autoInsert('newSupplierName', index, newElecCo);
                                      $refs.formNew.validate();
                                      $refs.formMain10.validate();"
                              @change="formChanged"
                            ></v-text-field>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical" rowspan="4">請求項目</th>
                          <th class="fixed-item-2">契約種別<span class="blue-text annotation" v-on:click="show()"> 注③</span></th>
                          <th class="fixed-item-3">-</th>
                          <td v-for="(monthly, index) in newElecCo" :key="monthly.index" :class="setBackColor('plan', index, newElecCo)">
                            <v-text-field 
                              v-model="monthly.plan" 
                              v-on:input="autoInsert('plan', index, newElecCo)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[requiredIf_ns(() => (saveRegist) && (!isEmpty(monthly.newSupplierName)))]"
                              @change="formChanged"
                            ></v-text-field>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">契約電力</th>
                          <th class="fixed-item-3">kw</th>
                          <td v-for="(monthly, index) in newElecCo" :key="monthly.index" class="yellow-back autoInsertRow">
                            <NumberTextField 
                              v-model="monthly.contract" 
                              v-on:input="autoInsert('contract', index, newElecCo)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">使用電力量</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyUse" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newEnergyUseCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newEnergyUseCalc, prevNewEnergyUseCalc)" readonly suffix="%" :class="diffPercentage(newEnergyUseCalc, prevNewEnergyUseCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">蓄熱電力量<span class="blue-text annotation" v-on:click="show()"> 注④</span></th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyStorage" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[requiredIf_ns(() => (saveRegist) && (!isEmptyCostStorage(newElecCo)) && (isEmptyEnergyStorage(newElecCo))), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              @input="$refs.formNew.validate()"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newEnergyStorageCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newEnergyStorageCalc, prevNewEnergyStorageCalc)" readonly suffix="%" :class="diffPercentage(newEnergyStorageCalc, prevNewEnergyStorageCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical" rowspan="5"><span class="blue-text annotation" v-on:click="show()">注⑤ </span>使用電力量内訳</th>
                          <th class="fixed-item-2">冷蔵電力量(荷捌室含む)</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyCold" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newEnergyColdCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newEnergyColdCalc, prevNewEnergyColdCalc)" readonly suffix="%" :class="diffPercentage(newEnergyColdCalc, prevNewEnergyColdCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">事務棟電力量</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyOffice" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newEnergyOfficeCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newEnergyOfficeCalc, prevNewEnergyOfficeCalc)" readonly suffix="%" :class="diffPercentage(newEnergyOfficeCalc, prevNewEnergyOfficeCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">製氷電力量<span class="blue-text annotation" v-on:click="show()"> 注⑥</span></th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="isEmpty(contactData.iceVolume) ? 'gray-back' : isEmptyEnergyIce(newElecCo) ? 'red-back' : 'yellow-back'">
                            <NumberTextField 
                              v-model="monthly.energyIce" 
                              :disabled="isEmpty(contactData.iceVolume)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newEnergyIceCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newEnergyIceCalc, prevNewEnergyIceCalc)" readonly suffix="%" :class="diffPercentage(newEnergyIceCalc, prevNewEnergyIceCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">凍結電力量<span class="blue-text annotation" v-on:click="show()"> 注⑦</span></th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="isEmpty(contactData.frozenVolume) ? 'gray-back' : isEmptyEnergyFrozen(newElecCo) ? 'red-back' : 'yellow-back'">
                            <NumberTextField 
                              v-model="monthly.energyFrozen" 
                              :disabled="isEmpty(contactData.frozenVolume)" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newEnergyFrozenCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newEnergyFrozenCalc, prevNewEnergyFrozenCalc)" readonly suffix="%" :class="diffPercentage(newEnergyFrozenCalc, prevNewEnergyFrozenCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">その他電力量</th>
                          <th class="fixed-item-3">kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.energyOther" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newEnergyOtherCalc" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newEnergyOtherCalc, prevNewEnergyOtherCalc)" readonly suffix="%" :class="diffPercentage(newEnergyOtherCalc, prevNewEnergyOtherCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical" rowspan="8"><span class="blue-text annotation" v-on:click="show()">注⑧ </span>請求書項目</th>
                          <th class="fixed-item-2">基本料金<span class="blue-text annotation" v-on:click="show()"> 注⑨</span></th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costBase" 
                              :class="monthly.costBase < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostBaseCalc" :class="newCostBaseCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostBaseCalc, prevNewCostBaseCalc)" readonly suffix="%" :class="diffPercentage(newCostBaseCalc, prevNewCostBaseCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">電力量料金</th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costEnergy" 
                              :class="monthly.costEnergy < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostEnergyCalc" :class="newCostEnergyCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostEnergyCalc, prevNewCostEnergyCalc)" readonly suffix="%" :class="diffPercentage(newCostEnergyCalc, prevNewCostEnergyCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">燃料費調整額</th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costFuel" 
                              :class="monthly.costFuel < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostFuelCalc" :class="newCostFuelCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostFuelCalc, prevNewCostFuelCalc)" readonly suffix="%" :class="diffPercentage(newCostFuelCalc, prevNewCostFuelCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">再エネ賦課金等<span class="blue-text annotation" v-on:click="show()"> 注⑩</span></th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.costReEnergy" 
                              :class="monthly.costReEnergy < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostReEnergyCalc" :class="newCostReEnergyCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostReEnergyCalc, prevNewCostReEnergyCalc)" readonly suffix="%" :class="diffPercentage(newCostReEnergyCalc, prevNewCostReEnergyCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">
                            <v-text-field 
                              v-model="otherNames.newCostOther1Name" 
                              label="項目入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"  
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newCostOther1Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.costOther1" 
                              :class="monthly.costOther1 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newCostOther1Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostOther1Calc" :class="newCostOther1Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostOther1Calc, prevNewCostOther1Calc)" readonly suffix="%" :class="diffPercentage(newCostOther1Calc, prevNewCostOther1Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">
                            <v-text-field 
                              v-model="otherNames.newCostOther2Name" 
                              label="項目入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newCostOther2Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.costOther2" 
                              :class="monthly.costOther2 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newCostOther2Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostOther2Calc" :class="newCostOther2Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostOther2Calc, prevNewCostOther2Calc)" readonly suffix="%" :class="diffPercentage(newCostOther2Calc, prevNewCostOther2Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2">
                            <v-text-field 
                              v-model="otherNames.newCostOther3Name" 
                              label="項目入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"  
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newCostOther3Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.costOther3" 
                              :class="monthly.costOther3 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newCostOther3Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostOther3Calc" :class="newCostOther3Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostOther3Calc, prevNewCostOther3Calc)" readonly suffix="%" :class="diffPercentage(newCostOther3Calc, prevNewCostOther3Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 green-text">賦課金減免の有無<span class="blue-text annotation" v-on:click="show()"> 注⑪</span></th>
                          <th class="fixed-item-3">-</th>
                          <td v-for="(monthly, index) in newElecCo" :key="monthly.index" class="yellow-back autoInsertRow">
                            <v-select
                              v-model="monthly.exemption"
                              item-text="text" 
                              item-value="value" 
                              :items="electricityExemption" 
                              v-on:input="autoInsert('exemption', index, newElecCo)" :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[requiredIf_select(() => (saveRegist) && (!isEmpty(monthly.newSupplierName)))]"
                              @change="formChanged"
                            ></v-select>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th class="fixed-item-1 vertical text-error" rowspan="6"><span class="blue-text annotation" v-on:click="show()">注⑫ </span>割引額</th>
                          <th class="fixed-item-2 text-error">産業用蓄熱調整契約<span class="blue-text annotation" v-on:click="show()"> 注④</span></th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="(!isEmptyEnergyStorage(newElecCo) && isEmpty(monthly.costStorage)) ? 'red-back' : 'yellow-back'">
                            <NumberTextField 
                              v-model="monthly.costStorage" 
                              :class="monthly.costStorage < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[requiredIf_ns(() => (saveRegist) && (!isEmptyEnergyStorage(newElecCo)) && (isEmptyCostStorage(main10ElecCo))), maxValLocaleJP(0), minValLocaleJP(-999999999.99)]"
                              @input="$refs.formNew.validate()"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newCostStorageCalc" :class="newCostStorageCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newCostStorageCalc, prevNewCostStorageCalc)" readonly suffix="%" :class="diffPercentage(newCostStorageCalc, prevNewCostStorageCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.newDiscountOther1Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"  
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newDiscountOther1Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther1" 
                              :class="monthly.discountOther1 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newDiscountOther1Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newDiscountOther1Calc" :class="newDiscountOther1Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newDiscountOther1Calc, prevNewDiscountOther1Calc)" readonly suffix="%" :class="diffPercentage(newDiscountOther1Calc, prevNewDiscountOther1Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.newDiscountOther2Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50" 
                              @change="formChanged" 
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newDiscountOther2Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther2" 
                              :class="monthly.discountOther2 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newDiscountOther2Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newDiscountOther2Calc" :class="newDiscountOther2Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newDiscountOther2Calc, prevNewDiscountOther2Calc)" readonly suffix="%" :class="diffPercentage(newDiscountOther2Calc, prevNewDiscountOther2Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.newDiscountOther3Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"  
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newDiscountOther3Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther3" 
                              :class="monthly.discountOther3 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newDiscountOther3Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newDiscountOther3Calc" :class="newDiscountOther3Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newDiscountOther3Calc, prevNewDiscountOther3Calc)" readonly suffix="%" :class="diffPercentage(newDiscountOther3Calc, prevNewDiscountOther3Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.newDiscountOther4Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"  
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newDiscountOther4Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther4" 
                              :class="monthly.discountOther4 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newDiscountOther4Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newDiscountOther4Calc" :class="newDiscountOther4Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newDiscountOther4Calc, prevNewDiscountOther4Calc)" readonly suffix="%" :class="diffPercentage(newDiscountOther4Calc, prevNewDiscountOther4Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr>
                          <th class="fixed-item-2 text-error">
                            <v-text-field 
                              v-model="otherNames.newDiscountOther5Name" 
                              label="割引入力欄" 
                              single-line 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :rules="[maxLength(50)]"
                              :maxlength="50"  
                              @change="formChanged"
                            ></v-text-field>
                          </th>
                          <th class="fixed-item-3 text-error">円</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="otherNames.newDiscountOther5Name ? 'yellow-back' : 'gray-back'">
                            <NumberTextField 
                              v-model="monthly.discountOther5" 
                              :class="monthly.discountOther5 < 0 ? 'text-error' : ''" 
                              :disabled="otherNames.newDiscountOther5Name ? false : true" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="100"
                              :rules="[maxValLocaleJP(999999999.99), minValLocaleJP(-999999999.99)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newDiscountOther5Calc" :class="newDiscountOther5Calc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newDiscountOther5Calc, prevNewDiscountOther5Calc)" readonly suffix="%" :class="diffPercentage(newDiscountOther5Calc, prevNewDiscountOther5Calc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">支払電力料金(請求書より)<span class="blue-text annotation" v-on:click="show()"> 注⑬</span></th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" class="yellow-back">
                            <NumberTextField 
                              v-model="monthly.payment" 
                              :class="monthly.payment < 0 ? 'text-error' : ''" 
                              :readonly="attribute == 0 && contactData.status >= 3"
                              :orgRoundStep="1"
                              :rules="[maxValLocaleJP(999999999), minValLocaleJP(0)]"
                              :changeEvt="formChanged"
                            ></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField v-model="newPaymentCalc" :class="newPaymentCalc < 0 ? 'text-error' : ''" readonly></NumberTextField>
                          </td>
                          <td>
                            <NumberTextField :value="diffPercentage(newPaymentCalc, prevNewPaymentCalc)" readonly suffix="%" :class="diffPercentage(newPaymentCalc, prevNewPaymentCalc) < 0 ? 'text-error' : ''"></NumberTextField>
                          </td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">基本料金÷契約電力kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" width="150" :class="costBaseContract(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="costBaseContract(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">電力量料金÷使用電力量kwh</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="costEnergyEnergyUse(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="costEnergyEnergyUse(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">使用電力量当り再エネ賦課金</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="costReEnergyEnergyUse(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="costReEnergyEnergyUse(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr class="tr-total">
                          <th colspan="3">項目合計金額と支払料金との差</th>
                          <td v-for="monthly in newElecCo" :key="monthly.index" :class="paymentDiff(monthly) < 0 ? 'text-error' : 'text-info'">
                            <NumberTextField :value="paymentDiff(monthly)" hide-details readonly></NumberTextField>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
              <v-row class="justify-space-between mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-self')" :disabled="loading" ><v-icon>mdi-chevron-left</v-icon>前へ</v-btn>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-note')" :disabled="loading">次へ<v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <!-- 備考 -->
          <v-tab-item value="tab-note" class="tab-item" :transition="false" eager>
            <v-form ref="formNote" v-model="validNote" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea 
                    label="備考欄" 
                    v-model="contactData.ansRemarks" 
                    :readonly="attribute == 0 && contactData.status >= 3"
                    :loading="loading"
                    :rules="[maxLength(1000)]"
                    :maxLength="1000"
                    :counter="1000"
                    @change="formChanged"
                  ></v-textarea>
                </v-col>
              </v-row>

              <!-- 追加設問 -->
              <v-row v-if="qaList.length > 0">
                <v-col>
                  <h3>その他の追加設問</h3>
                </v-col>
              </v-row>
              <v-row v-for="(qaData, index) in qaList" :key="qaData.qaSid">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    :label="(index+1)+'.'+qaData.question"
                    v-model="qaData.answer"
                    hide-details
                    dense
                    :readonly="attribute == 0 && contactData.status >= 3"
                    :clearable="false"
                    :rules="[maxLength(100)]"
                    :maxLength="100"
                    :loading="loading"
                    rows="2"
                    @change="formChanged"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row class="justify-start mt-2 mb-0">
                <v-col cols="3" sm="3" md="3" lg="3" class="text-center pb-0">
                  <v-btn outlined color="primary" width="120" v-on:click="setActiveTab('tab-new')" :disabled="loading" ><v-icon>mdi-chevron-left</v-icon>前へ</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <!-- アクション -->
    <v-row class="mt-0 mb-0 pt-0 pb-0">
      <v-col class="pt-0 pb-0">
        <!-- <h4>このたびはお忙しいところご回答いただき、まことにありがとうございました。</h4> -->
        <div>
          ■連絡先(一社)日本冷蔵倉庫協会技術部&nbsp;&nbsp;&nbsp;&nbsp;E-ﾒｰﾙ：gijutsu@jarw.or.jp&nbsp;&nbsp;&nbsp;&nbsp;FAX：03-3536-1031
        </div>
      </v-col>
    </v-row>

    <!-- modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="true" :scrollable="true" 
                                height="80%" width="80%" id="vm--modal">
      <div class="modal-wrap">
        <div class="d-flex modal-header justify-space-between">
          <h2>記入要項</h2>
          <v-icon x-large v-on:click="hide()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <div class="flex">
            <div class="blue-text">注①</div>
            <div>基礎情報は、{{ asOfMonth | formatDate('YYYY年M月') }}末時点をご記入ください。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注②</div>
            <div>供給電力会社名をご記入ください。<br />【１】１０大電力の北海道電力～沖縄電力はプルダウンメニューから選択。<br /><span class="text-error">【３】新電力は直接社名を入れてください。</span></div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注③</div>
            <div>契約種別は、「高圧A、高圧B、高圧BS蓄熱特約S、高圧季時別電力AⅡ型等」をご記入ください。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注④</div>
            <div>蓄熱電力量（kwh）を入力しないと産業用蓄熱調整契約（円）は入力できません。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑤</div>
            <div>使用電力量内訳は、毎月毎にわかる範囲でご記入ください。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑥</div>
            <div>製氷年間生産量（ﾄﾝ/年を入力しないと製氷電力量（kwh）は入力できません。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑦</div>
            <div>凍結年間生産量（ﾄﾝ/年を入力しないと凍結電力量（kwh）は入力できません。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑧</div>
            <div>請求書項目の「項目入力欄」に入力しないと金額は入力できません。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑨</div>
            <div>基本料金は、<span class="underline">力率割引額・力率修正額を加味した金額</span>をご記入ください。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑩</div>
            <div>再エネ（太陽光、風力発電など）賦課金等は、<span class="underline">減免額を加味した金額</span>をご記入ください。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑪</div>
            <div>再エネ賦課金の減免の有無を、プルダウンメニューより選択してください。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑫</div>
            <div>割引額の「割引入力欄」に入力しないと金額は入力できません。<br />割引額は<span class="text-error">「-」を付けてください。（割引額5,000円の場合「-5,000」）</span><br />記載している項目以外の割引項目がありましたら、その他の欄をご使用ください。</div>
          </div>
          <br />
          <div class="flex">
            <div class="blue-text">注⑬</div>
            <div>
              支払電力料金は、毎月の<span class="underline">請求書の請求金額を確認して記入してください。（円未満切捨て）</span
              ><br />請求書項目及び割引額の合計金額と、支払電力料金（請求書より）が同じ金額となるように入力してください。<br />請求書項目の合計金額を計算する際、燃料費調整額や賦課金、消費税などが重複しないように注意してください。<br />重複する金額は、電力量料金から差し引いてください。
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!-- modal -->
    <modal name="import-modal-dialog" :draggable="false" :clickToClose="true" :scrollable="true" 
                                height="200px" width="80%" id="vm--modal-import">
      <div class="modal-wrap">
        <div class="d-flex modal-header mt-8 pb-8 justify-space-between">
          <h2>回答票インポート</h2>
          <v-icon x-large v-on:click="hideImport()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <v-container>
            <v-row class="">
              <v-col cols="12">
                回答票Excelを選択してください。
              </v-col>
            </v-row>
            <v-row class="justify-space-between">
              <v-col cols="8">
                <v-file-input 
                  label="回答票Excelファイル" 
                  v-model="excelFile" 
                  dense 
                  accept=".xlsx"
                  truncate-length="30"
                  prepend-icon="mdi-import"
                ></v-file-input>
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <v-btn 
                  class="btn_font primary mr-2" 
                  width="150" 
                  v-on:click="importExcel()"
                  :disabled="loading || (excelFile == null)" >
                  回答票インポート
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </modal>
  
  </v-container>
</template>

<script>
import Debug from "../Lib/Debug";
import Message from "../Lib/Message";
import Mixin from "../mixins/const.js";
import dayjs from "dayjs";
import Util from '../Lib/Util';
import * as XLSX from "xlsx";
import BigNumber from "bignumber.js";
import XlsxImport from '../mixins/excel_import';

import NumberTextField from "./common/Number_text_field.vue";
import DatePicker from "./common/DatePicker.vue";

import ElectricityAnswer from "../model/electricityAnswer.js";
import ElectricityDest from "../model/electricityDest.js";

import AlertSnackbar from "./common/AlertSnackbar.vue";

// import CvTextField from "./common/Cv_text_field.vue";

export default {
  mixins: [Mixin, XlsxImport],

  components: { NumberTextField, DatePicker, AlertSnackbar },

  data: () => ({
    validBase: true,
    validMain10: true,
    validSelf: true,
    validNew: true,
    validNote: true,
    loading: false,

    // ログインユーザ属性
    attribute: 0,

    openPanel: 0, // 記入要項パネルをデフォルトで開いておく

    surveyId: "",
    memberNo: "",
    branch: "",
    branchName: "",
    extractNewElecCoNames: [],

    // 回答データ
    targetYear: null,
    officeData: {},
    contactData: {},
    otherNames: {},
    main10ElecCo: [],
    newElecCo: [],
    selfGeneration: [],
    note: "",

    asOfMonth: '',
    addNote: "",
    qaList: [],

    prevMain10ElecCo: [],
    prevNewElecCo: [],
    prevSelfGeneration: [],

    dest: {},

    // 回答受付期間変更
    editAnsPeriodMode: false,

    // 1つ前の回答ステータス
    oldStatus: null,

    // アクション
    action: false,

    // バリデーション用 回答フラグ
    saveRegist: false,

    // タブ
    tabId: "",

    // formバリデーション
    formBaseValid: true,
    formMain10Valid: true,
    formSelfValid: true,
    formNewValid: true,
    formNoteValid: true,

    // file
    excelFile: null,
  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    this.branchName = this.$store.getters.getTargetBranchName;     // 編集する区別名をstoreから取得
    // this.makeArray();
    this.loadData();
  },

  mounted: function () {},

  methods: {
    // 変更
    formChanged() {
      if(!this.loading) {
        this.$store.commit('setFormChanged', true);
      }
    },

    // 表示更新
    async loadData() {
      Debug.log("function[loadData]");
      this.loading = true;
      this.surveyId = this.$store.getters.getTargetSurveyId; // 編集する調査IDをstoreから取得
      this.memberNo = this.$store.getters.getTargetMemberNo; // 編集する会員番号をstoreから取得
      this.branch = this.$store.getters.getTargetBranch;     // 編集する区別をstoreから取得

      // DBからデータ取得
      try {
        const res = await ElectricityAnswer.getAnswerData(this.surveyId, this.memberNo, this.branch);
        Debug.log(res.data);

        if (res.data) {
          // 調査対象年度
         // this.targetYear = Number(res.data.targetYear);
          this.targetYear = res.data.targetYear;

          // 事業所会員データ
          this.officeData = res.data.officeData;

          // 基礎情報データ
          this.contactData = res.data.contactData;
          if (!this.contactData["volumeTotal"]) {
            this.contactData["volumeTotal"] = this.officeData["volume"]; // 初回のみ日冷倉協届出の所管容積を表示
          }
          this.contactData.branchName = this.branchName;  // 区別名を追加

          // 回答票データ
          this.main10ElecCo = res.data.answerDetail.main10ElecCo;
          this.newElecCo = res.data.answerDetail.newElecCo;
          this.selfGeneration = res.data.answerDetail.selfGeneration;

          // 回答票その他の名称データ
          this.otherNames = res.data.otherNames;

          // 基礎情報年月
          this.asOfMonth = res.data.asOfMonth;
          // 追加の注意書き
          this.addNote = res.data.addNote;

          // 追加設問
          this.qaList = res.data.qaList;

          // 調査宛先
          this.dest = res.data.dest;

          // 前年回答データ
          this.prevMain10ElecCo = res.data.prevAnswerDetail.main10ElecCo;
          this.prevNewElecCo = res.data.prevAnswerDetail.newElecCo;
          this.prevSelfGeneration = res.data.prevAnswerDetail.selfGeneration;

          if(this.contactData.status == 0) this.show();
        }
      } catch (error) {
        Message.err(error, "電力調査回答を取得できませんでした");
      }

      this.$nextTick(() => {
        this.$store.commit('setFormChanged', false);
      });
      this.loading = false;
    },

    // 入力したセル以降の月のデータを自動入力する
    autoInsert(keyName, index, elecCoType) {
      for (let i = index; i < 11; i++) {
        elecCoType[i + 1][keyName] = elecCoType[i][keyName];
      }
      elecCoType.splice(); // 10大電力会社名の12月以降で上手く動作しないため更新用
    },

    // 電力会社、契約種別が途中で変更となった時に変更月以降の背景色を変える
    setBackColor(keyName, index, elecCoType) {
      let nameList = [];
      const aprilData = elecCoType[0][keyName];
      elecCoType.forEach((elem) => {
        if (elem[keyName] != null && elem[keyName] != "") {
          nameList.push(elem[keyName]);
        }
      });
      const uniqueNameArray = Array.from(new Set(nameList));

      if (index == 0) {
        return "yellow-back"; // 4月は黄色固定
      } else if (elecCoType[index][keyName] == "" || elecCoType[index][keyName] === null || elecCoType[index][keyName] == aprilData || elecCoType[index][keyName] == uniqueNameArray[0]) {
        return ""; // 未入力は白色固定
      } else if (elecCoType[index][keyName] == uniqueNameArray[1]) {
        return "green-back";
      } else {
        return "blue-back";
      }
    },

    // 一時保存
    saveData() {
      Debug.log("function[saveData]");

      this.formBaseValid = this.$refs.formBase.validate();
      this.formMain10Valid = this.$refs.formMain10.validate();
      this.formSelfValid = this.$refs.formSelf.validate();
      this.formNewValid = this.$refs.formNew.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formMain10Valid ||
            !this.formSelfValid ||
            !this.formNewValid ||
            !this.formNoteValid ) {
        let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formMain10Valid) msg.push("10大電力会社");
        if(!this.formSelfValid) msg.push("自家発電");
        if(!this.formNewValid) msg.push("新電力会社");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("一時保存します。よろしいですか？")) return;

      this.action = true;
      this.registData(1); // DBに登録
    },
    // 回答
    async answerData() {
      Debug.log("function[answerData]");

      this.saveRegist = true;

      this.formBaseValid = this.$refs.formBase.validate();
      this.formMain10Valid = this.$refs.formMain10.validate();
      this.formSelfValid = this.$refs.formSelf.validate();
      this.formNewValid = this.$refs.formNew.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formMain10Valid ||
            !this.formSelfValid ||
            !this.formNewValid ||
            !this.formNoteValid ) {
              let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formMain10Valid) msg.push("10大電力会社");
        if(!this.formSelfValid) msg.push("自家発電");
        if(!this.formNewValid) msg.push("新電力会社");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        this.saveRegist = false;
        return;
      }

      if (!confirm("回答を確定します。よろしいですか？")) return;

      this.action = true;
      this.registData(3); // DBに登録
    },
    // 取消
    cancel() {
      if (!confirm("キャンセルします。よろしいですか？")) return;
      this.$router.back();
    },
    // 差戻し
    async sendBackData() {
      this.formBaseValid = this.$refs.formBase.validate();
      this.formMain10Valid = this.$refs.formMain10.validate();
      this.formSelfValid = this.$refs.formSelf.validate();
      this.formNewValid = this.$refs.formNew.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formMain10Valid ||
            !this.formSelfValid ||
            !this.formNewValid ||
            !this.formNoteValid ) {
        let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formMain10Valid) msg.push("10大電力会社");
        if(!this.formSelfValid) msg.push("自家発電");
        if(!this.formNewValid) msg.push("新電力会社");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("回答を保存して確定を解除します。解除後は事業所が回答を変更できるようになります。よろしいですか？")) return;

      this.action = true;
      this.registData(1, true);
    },

    // DBに登録
    async registData(status, sendBack=false) {
      Debug.log('registData');
      this.loading = true;
      this.contactData.status = status; // statusを変更

      Debug.log(this.memberNo);

      try {
        await ElectricityAnswer.setData(this.surveyId, 
                                        this.memberNo, 
                                        this.branch,
                                        this.contactData, 
                                        this.otherNames, 
                                        this.main10ElecCo, 
                                        this.newElecCo, 
                                        this.selfGeneration, 
                                        this.qaList);
      } catch (error) {
        Message.err(error, this.contactData.status == 1 && sendBack ? "確定を解除できませんでした。" : 
                            this.contactData.status == 1 ? "一時保存できませんでした。" : "回答を確定できませんでした。");
        this.loading = false;
      }

      this.$store.commit('setFormChanged', false);

      let resMsg = '保存しました。';
      if(this.contactData.status == 1 && sendBack) {
        resMsg = '確定を解除しました。';
      } else if(this.contactData.status == 1) {
        resMsg = '一時保存しました。';
      } else if(this.contactData.status == 3) {
        resMsg = 'このたびはお忙しいところご回答いただき、まことにありがとうございました。';
      }
      alert(resMsg);

      this.action = false;
      this.$router.back();
    },

    check1(costbase, contract) {
      return costbase / contract;
    },

    // 未入力チェック
    isEmpty(value) {
      return value == null || value == '';
    },

    // 未入力チェック(いずれかの月が入力されていればOK)
    isEmptyEnergyIce(list) {
      let cnt = 0;

      list.forEach((value) => {
        if (value.energyIce != null && value.energyIce != '' && value.energyIce != 0) {
          cnt++;
        }
      
      });

      if(cnt > 0) {
        return false;
      } else {
        return true;
      }
    },
    // 未入力チェック(いずれかの月が入力されていればOK)
    isEmptyEnergyFrozen(list) {
      let cnt = 0;

      list.forEach((value) => {
        if (value.energyFrozen != null && value.energyFrozen != '' && value.energyFrozen != 0) {
          cnt++;
        }
      
      });

      if(cnt > 0) {
        return false;
      } else {
        return true;
      }
    },
    // 未入力チェック(いずれかの月が入力されていればOK)
    isEmptyEnergyStorage(list) {
      let cnt = 0;

      list.forEach((value) => {
        if (value.energyStorage != null && value.energyStorage != '' && value.energyStorage != 0) {
          cnt++;
        }

      });

      if(cnt > 0) {
        return false;
      } else {
        return true;
      }
    },
    // 未入力チェック(いずれかの月が入力されていればOK)
    isEmptyCostStorage(list) {
      let cnt = 0;

      list.forEach((value) => {
        if (value.costStorage != null && value.costStorage != '' && value.costStorage != 0) {
          cnt++;
        }

      });

      if(cnt > 0) {
        return false;
      } else {
        return true;
      }
    },

    // 数値チェック用
    // 基本料金÷契約電力kwh
    costBaseContract(value) {
      let res = 0;
      if(value.contract != null && value.contract != 0) {
        res = value.costBase / value.contract;
      }

      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 電力量料金÷使用電力量kwh
    costEnergyEnergyUse(value) {
      let res = 0;
      if(value.energyUse != null && value.energyUse != 0) {
        res = value.costEnergy / value.energyUse;
      }

      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 使用電力量当り再エネ賦課金
    costReEnergyEnergyUse(value) {
      let res = 0;
      if(value.energyUse != null && value.energyUse != 0) {
        // 再エネ賦課金等÷使用電力量
        res = value.costReEnergy / value.energyUse;
      }

      // 少数第3位で四捨五入
      return Util.orgRound(res, 100);
    },
    // 項目合計金額と支払料金との差
    paymentDiff(value) {
      let res = 0;
      
      // (基本料金+電力量料金+燃料費調整額+再エネ賦課金等
      //   +請求書項目 その他1+請求書項目 その他2+請求書項目 その他3
      //   +産業用蓄熱調整契約+割引額 その他1+割引額 その他2+割引額 その他3+割引額 その他4+割引額 その他5)-支払電力料金
      res = (value.costBase + value.costEnergy + value.costFuel + value.costReEnergy
              + value.costOther1 + value.costOther2 + value.costOther3
              + value.costStorage + value.discountOther1 + value.discountOther2 + value.discountOther3 + value.discountOther4 + value.discountOther5) - value.payment;

      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },

    // 前年回答との乖離幅%
    diffPercentage(thisValue, prevValue) {
      let res = 0;

      // 前年比率
      // if(this.prevEnergyUseCalc != 0) {
      //   // 今回回答÷前年回答×100
      //   res = thisValue / prevValue * 100;
      // }

      // 差分
      // if(((thisValue + prevValue) / 2) != 0) {
      //   // (今回回答-前年回答)÷((今回回答+前年回答)÷2)×100
      //   res = (thisValue - prevValue) / ((thisValue + prevValue) / 2) * 100;
      // }

      // 上昇減少率
      if(prevValue != 0) {
        // (今回回答-前年回答)÷前年回答×100
        res = (thisValue - prevValue) / prevValue * 100;
      }

      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },

    /** 回答受付期間の変更 */
    // キャンセル
    cancelAnsPeriod() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      //this.search();
      this.editAnsPeriodMode = false;
    },
    // 変更
    editAnsPeriod() {
      this.editAnsPeriodMode = true;
    },
    // 保存
    async registAnsPeriod() {
      if (!confirm("回答受付期間を保存します。よろしいですか？")) return;

      this.loading = true;
      try {
        const res = await ElectricityDest.setAnsPeriod(this.dest);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }

      } catch (error) {
        Message.err(error, "保存できませんでした");
      }
      this.loading = false;
      this.editAnsPeriodMode = false;

      // データを再取得
      //this.search();
    },

    // ステータス変更
    async registStatus() {
      if(this.contactData.status == 3) {
        this.saveRegist = true;
      } else {
        this.saveRegist = false;
      }

      this.formBaseValid = this.$refs.formBase.validate();
      this.formMain10Valid = this.$refs.formMain10.validate();
      this.formSelfValid = this.$refs.formSelf.validate();
      this.formNewValid = this.$refs.formNew.validate();
      this.formNoteValid = this.$refs.formNote.validate();

      if ( !this.formBaseValid ||
            !this.formMain10Valid ||
            !this.formSelfValid ||
            !this.formNewValid ||
            !this.formNoteValid ) {
              let msg = [];
        if(!this.formBaseValid) msg.push("基礎情報");
        if(!this.formMain10Valid) msg.push("10大電力会社");
        if(!this.formSelfValid) msg.push("自家発電");
        if(!this.formNewValid) msg.push("新電力会社");
        if(!this.formNoteValid) msg.push("備考");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        if(this.contactData.status == 3) {
          this.saveRegist = false;
        }

        // ステータスを元に戻して返却
        this.$nextTick(() => {
          this.contactData.status = this.oldStatus;
        });
        return;
      }

      if (!confirm("回答を保存します。よろしいですか？")) {
        // ステータスを元に戻して返却
        this.$nextTick(() => {
          this.contactData.status = this.oldStatus;
        });
        return;
      }

      this.action = true;
      this.registData(this.contactData.status); // DBに登録
    },

    /** モーダル表示用 */
    show () {
      Debug.log('function[show]');

      this.$modal.show('modal-dialog');
    },
    hide : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog');
    },

    /** タブ */
    setActiveTab(tabId) {
      Debug.log('function[setActiveTab]');
      this.tabId = tabId;
    },

    /** 回答票Excelインポート */
    showImport () {
      Debug.log('function[showImport]');

      this.$modal.show('import-modal-dialog');
    },
    hideImport : function () {
      Debug.log('function[hideImport]');
      this.$modal.hide('import-modal-dialog');
    },

    getExt(fileName) {
      var pos = fileName.lastIndexOf('.');
      if (pos === -1) return '';
      return fileName.slice(pos + 1);
    },

    importExcel() {
      const e = this.excelFile;
      Debug.log2('file', e);
      Debug.log2('ext', this.getExt(e.name));
      if(this.getExt(e.name) !== 'xlsx'){
        alert("xlsx形式のファイルを指定してください。");
        return;
      }

      if (!confirm("回答票Excelの入力値をコピーします。よろしいですか？")) return;

      var reader = new FileReader();
      reader.onload = function (e) {
        var unit8 = new Uint8Array(e.target.result);

        const workbook = XLSX.read(unit8, {type: "array"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet);

        Debug.log2('rows', rows);

        // ===== データコピー =====
        //this.$nextTick(() => {

        // 基礎情報
        // 所管容積合計はExcelに入力がある場合のみコピー
        const tmpInputVolumeTotal = this.getCellValue(sheet, 'O6', 'v', true);
        if(typeof tmpInputVolumeTotal !== "undefined" && tmpInputVolumeTotal !== null) {
          this.$set(this.contactData, "volumeTotal", Util.orgRound(tmpInputVolumeTotal, 1));    // 所管容積合計
        }
        this.$set(this.contactData, "iceVolume", Util.orgRound(this.getCellValue(sheet, 'O7', 'v'), 1));      // 製氷年間生産量
        this.$set(this.contactData, "frozenVolume", Util.orgRound(this.getCellValue(sheet, 'O8', 'v'), 1));   // 凍結年間生産量
        this.$set(this.contactData, "responderName", this.getCellValue(sheet, 'C9', 'w'));                    // 担当者名
        this.$set(this.contactData, "tel", this.getCellValue(sheet, 'C10', 'w'));                             // 電話       
        this.$set(this.contactData, "email", this.getCellValue(sheet, 'H10', 'w'));                           // メール

        // 10大電力会社
        this.$set(this.otherNames, 'costOther1Name', this.getCellValue(sheet, 'B37', 'w'));        // 請求その他1項目名
        this.$set(this.otherNames, 'costOther2Name', this.getCellValue(sheet, 'B38', 'w'));        // 請求その他2項目名
        this.$set(this.otherNames, 'costOther3Name', this.getCellValue(sheet, 'B39', 'w'));        // 請求その他3項目名
        this.$set(this.otherNames, 'discountOther1Name', this.getCellValue(sheet, 'B42', 'w'));    // 割引1項目名
        this.$set(this.otherNames, 'discountOther2Name', this.getCellValue(sheet, 'B43', 'w'));    // 割引2項目名
        this.$set(this.otherNames, 'discountOther3Name', this.getCellValue(sheet, 'B44', 'w'));    // 割引3項目名
        this.$set(this.otherNames, 'discountOther4Name', this.getCellValue(sheet, 'B45', 'w'));    // 割引4項目名
        this.$set(this.otherNames, 'discountOther5Name', this.getCellValue(sheet, 'B46', 'w'));    // 割引5項目名
        
        let col = 3;
        for(let i=0; i<this.main10ElecCo.length; i++) {
          let row = 22;

          this.$set(this.main10ElecCo[i], 'supplier', this.getSupplier(sheet, this.getEncode_cell(col, row++)));      // 電力会社名

          this.$set(this.main10ElecCo[i], 'plan', this.getCellValue(sheet, this.getEncode_cell(col, row++), 'w'));    // 契約種別
          this.$set(this.main10ElecCo[i], 'contract', Util.orgRound(
                                                        this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                        1));    // 契約電力
          this.$set(this.main10ElecCo[i], 'energyUse', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          1));    // 使用電力量
          this.$set(this.main10ElecCo[i], 'energyStorage', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                              1));    // 蓄熱電力量

          this.$set(this.main10ElecCo[i], 'energyCold', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          1));    // 冷蔵電力量
          this.$set(this.main10ElecCo[i], 'energyOffice', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            1));    // 事務棟電力量
          this.$set(this.main10ElecCo[i], 'energyIce', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          1));    // 製氷電力量
          this.$set(this.main10ElecCo[i], 'energyFrozen', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            1));    // 凍結電力量
          this.$set(this.main10ElecCo[i], 'energyOther', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            1));    // その他電力量

          this.$set(this.main10ElecCo[i], 'costBase', Util.orgRound(
                                                        this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                        100));    // 基本料金
          this.$set(this.main10ElecCo[i], 'costEnergy', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // 電力量料金
          this.$set(this.main10ElecCo[i], 'costFuel', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // 燃料費調整額
          this.$set(this.main10ElecCo[i], 'costReEnergy', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            100));    // 再エネ賦課金等
          this.$set(this.main10ElecCo[i], 'costOther1', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // その他1
          this.$set(this.main10ElecCo[i], 'costOther2', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // その他2
          this.$set(this.main10ElecCo[i], 'costOther3', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // その他3
          this.$set(this.main10ElecCo[i], 'exemption', Util.orgRound(
                                                          this.getExemption(sheet, this.getEncode_cell(col, row++)),
                                                          100));      // 賦課金減免の有無
          this.$set(this.main10ElecCo[i], 'costStorage', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                            100));    // 産業用蓄熱調整契約
          this.$set(this.main10ElecCo[i], 'discountOther1', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他1
          this.$set(this.main10ElecCo[i], 'discountOther2', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他2
          this.$set(this.main10ElecCo[i], 'discountOther3', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他3
          this.$set(this.main10ElecCo[i], 'discountOther4', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他4
          this.$set(this.main10ElecCo[i], 'discountOther5', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他5
          this.$set(this.main10ElecCo[i], 'payment', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                              1));    // 支払電力料金

          col++;
        }

        // 自家発電
        this.$set(this.otherNames, 'selfGeneration1Select', this.getSelfGeneration(sheet, 'B53'));      // 自家発電機種名1
        this.$set(this.otherNames, 'selfGeneration2Select', this.getSelfGeneration(sheet, 'B54'));      // 自家発電機種名2
        this.$set(this.otherNames, 'selfGeneration3Select', this.getSelfGeneration(sheet, 'B55'));      // 自家発電機種名3

        col = 3;
        for(let i=0; i<this.selfGeneration.length; i++) {
          let row = 52;

          this.$set(this.selfGeneration[i], 'selfGeneration1', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              1));    // 自家発電1(kwh)

          this.$set(this.selfGeneration[i], 'selfGeneration2', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              1));    // 自家発電2(kwh)

          this.$set(this.selfGeneration[i], 'selfGeneration3', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              1));    // 自家発電3(kwh)

          col++;
        }

        // 新電力会社
        this.$set(this.otherNames, 'newCostOther1Name', this.getCellValue(sheet, 'B76', 'w'));        // 請求その他1項目名
        this.$set(this.otherNames, 'newCostOther2Name', this.getCellValue(sheet, 'B77', 'w'));        // 請求その他2項目名
        this.$set(this.otherNames, 'newCostOther3Name', this.getCellValue(sheet, 'B78', 'w'));        // 請求その他3項目名
        this.$set(this.otherNames, 'newDiscountOther1Name', this.getCellValue(sheet, 'B81', 'w'));    // 割引1項目名
        this.$set(this.otherNames, 'newDiscountOther2Name', this.getCellValue(sheet, 'B82', 'w'));    // 割引2項目名
        this.$set(this.otherNames, 'newDiscountOther3Name', this.getCellValue(sheet, 'B83', 'w'));    // 割引3項目名
        this.$set(this.otherNames, 'newDiscountOther4Name', this.getCellValue(sheet, 'B84', 'w'));    // 割引4項目名
        this.$set(this.otherNames, 'newDiscountOther5Name', this.getCellValue(sheet, 'B85', 'w'));    // 割引5項目名
        
        col = 3;
        for(let i=0; i<this.newElecCo.length; i++) {
          let row = 61;

          this.$set(this.newElecCo[i], 'newSupplierName', this.getCellValue(sheet, this.getEncode_cell(col, row++), 'w'));      // 電力会社名

          this.$set(this.newElecCo[i], 'plan', this.getCellValue(sheet, this.getEncode_cell(col, row++), 'w'));    // 契約種別
          this.$set(this.newElecCo[i], 'contract', Util.orgRound(
                                                        this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                        1));    // 契約電力
          this.$set(this.newElecCo[i], 'energyUse', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          1));    // 使用電力量
          this.$set(this.newElecCo[i], 'energyStorage', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                              1));    // 蓄熱電力量

          this.$set(this.newElecCo[i], 'energyCold', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          1));    // 冷蔵電力量
          this.$set(this.newElecCo[i], 'energyOffice', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            1));    // 事務棟電力量
          this.$set(this.newElecCo[i], 'energyIce', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          1));    // 製氷電力量
          this.$set(this.newElecCo[i], 'energyFrozen', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            1));    // 凍結電力量
          this.$set(this.newElecCo[i], 'energyOther', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            1));    // その他電力量

          this.$set(this.newElecCo[i], 'costBase', Util.orgRound(
                                                        this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                        100));    // 基本料金
          this.$set(this.newElecCo[i], 'costEnergy', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // 電力量料金
          this.$set(this.newElecCo[i], 'costFuel', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // 燃料費調整額
          this.$set(this.newElecCo[i], 'costReEnergy', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                            100));    // 再エネ賦課金等
          this.$set(this.newElecCo[i], 'costOther1', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // その他1
          this.$set(this.newElecCo[i], 'costOther2', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // その他2
          this.$set(this.newElecCo[i], 'costOther3', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                          100));    // その他3
          this.$set(this.newElecCo[i], 'exemption', Util.orgRound(
                                                          this.getExemption(sheet, this.getEncode_cell(col, row++)),
                                                          100));      // 賦課金減免の有無
          this.$set(this.newElecCo[i], 'costStorage', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                            100));    // 産業用蓄熱調整契約
          this.$set(this.newElecCo[i], 'discountOther1', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他1
          this.$set(this.newElecCo[i], 'discountOther2', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他2
          this.$set(this.newElecCo[i], 'discountOther3', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他3
          this.$set(this.newElecCo[i], 'discountOther4', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他4
          this.$set(this.newElecCo[i], 'discountOther5', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v', true),
                                                              100));    // その他5
          this.$set(this.newElecCo[i], 'payment', Util.orgRound(
                                                              this.getCellValue(sheet, this.getEncode_cell(col, row++), 'v'),
                                                              1));    // 支払電力料金

          col++;
        }

        // 備考
        this.contactData.ansRemarks = this.getCellValue(sheet, 'B90', 'w');

      }.bind(this);
      reader.readAsArrayBuffer(e);

      this.hideImport();
    },

    getSupplier(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      tmpCell = (typeof tmpCell !== "undefined" && tmpCell !== null) ? tmpCell : ''
      let tmpVal = this.electricityElecCoName.find(function(v) { 
                      return v.text == tmpCell;
                    });

      return tmpVal.value;
    },

    getExemption(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      tmpCell = (typeof tmpCell !== "undefined" && tmpCell !== null) ? tmpCell : ''
      let tmpVal = this.electricityExemption.find(function(v) { 
                      return v.text == tmpCell;
                    });

      return tmpVal.value;
    },

    getOtherName(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      
      if(typeof tmpCell !== "undefined" && tmpCell !== null) {
        tmpCell = tmpCell.slice(4);
        tmpCell = tmpCell.slice(0, -1);
        tmpCell = tmpCell.trim();
      }

      return tmpCell;
    },

    getSelfGeneration(sheet, cell) {
      let tmpCell = this.getCellValue(sheet, cell, 'w');
      tmpCell = (typeof tmpCell !== "undefined" && tmpCell !== null) ? tmpCell : ''
      let tmpVal = this.electricitySelfGeneration.find(function(v) { 
                      return v.text == tmpCell;
                    });

      return tmpVal.value;
    },
  },

  watch: {
    'contactData.status': function(newValue, oldValue){
      // 一時保存、確定、確定解除で変更された場合は何もしない
      if(this.action) return;

      // プルダウンからステータスが変更された場合
      if( (typeof oldValue != 'undefined') && 
          ( (this.oldStatus == null) || (this.oldStatus != newValue) )) {
          this.oldStatus = oldValue;
          this.registStatus();
      }
    },

    'contactData.iceVolume': function(newValue){
      // 製氷年間生産量の変更
      if(this.isEmpty(newValue)) {
        // 入力値が0または未入力の場合、10大・新電力の製氷電力量をリセットする
        for(let i = 0 ; i < this.main10ElecCo.length ; i++) {
          this.$set(this.main10ElecCo[i], 'energyIce', null); 
        }
        for(let i = 0 ; i < this.newElecCo.length ; i++) {
          this.$set(this.newElecCo[i], 'energyIce', null); 
        }
      }
    },

    'contactData.frozenVolume': function(newValue){
      // 凍結年間生産量の変更
      if(this.isEmpty(newValue)) {
        // 入力値が0または未入力の場合、10大・新電力の製氷電力量をリセットする
        for(let i = 0 ; i < this.main10ElecCo.length ; i++) {
          this.$set(this.main10ElecCo[i], 'energyFrozen', null); 
        }
        for(let i = 0 ; i < this.newElecCo.length ; i++) {
          this.$set(this.newElecCo[i], 'energyFrozen', null); 
        }
      }
    },
    
    'contactData.email': function(newValue){
      // メール欄に半角全角スペース入力不可
      if(newValue != null)
      {
        const inputCheck = /\s+/g; // スペースの正規表現
        this.$nextTick(() => {
          this.$set(this.contactData, 'email', newValue.replace(inputCheck, ''));
        });
      }
    },
  },

  computed: {
    // 調査対象年
    ansTargetYear() {
      if (!this.targetYear || this.targetYear == null) return "";
      const targetDate = dayjs(this.targetYear);
      return targetDate.subtract(1, 'y');
    },
    // 調査対象翌年
    ansNextYear() {
      if (!this.targetYear || this.targetYear == null) return "";
      const targetDate = dayjs(this.targetYear);
      return targetDate;
    },
    energyUseCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.energyUse) {
          sum += monthly.energyUse;
        }
      });
      return sum;
    },
    energyStorageCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.energyStorage) {
          sum += monthly.energyStorage;
        }
      });
      return sum;
    },
    energyColdCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.energyCold) {
          sum += monthly.energyCold;
        }
      });
      return sum;
    },
    energyOfficeCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.energyOffice) {
          sum += monthly.energyOffice;
        }
      });
      return sum;
    },
    energyIceCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.energyIce) {
          sum += monthly.energyIce;
        }
      });
      return sum;
    },
    energyFrozenCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.energyFrozen) {
          sum += monthly.energyFrozen;
        }
      });
      return sum;
    },
    energyOtherCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.energyOther) {
          sum += monthly.energyOther;
        }
      });
      return sum;
    },
    costBaseCalc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costBase) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costBase);
        }
      });
      return sum.toNumber();
    },
    costEnergyCalc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costEnergy) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costEnergy);
        }
      });
      return sum.toNumber();
    },
    costFuelCalc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costFuel) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costFuel);
        }
      });
      return sum.toNumber();
    },
    costReEnergyCalc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costReEnergy) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costReEnergy);
        }
      });
      return sum.toNumber();
    },
    costOther1Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costOther1) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costOther1);
        }
      });
      return sum.toNumber();
    },
    costOther2Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costOther2) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costOther2);
        }
      });
      return sum.toNumber();
    },
    costOther3Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costOther3) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costOther3);
        }
      });
      return sum.toNumber();
    },
    costStorageCalc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.costStorage) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costStorage);
        }
      });
      return sum.toNumber();
    },
    discountOther1Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.discountOther1) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther1);
        }
      });
      return sum.toNumber();
    },
    discountOther2Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.discountOther2) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther2);
        }
      });
      return sum.toNumber();
    },
    discountOther3Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.discountOther3) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther3);
        }
      });
      return sum.toNumber();
    },
    discountOther4Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.discountOther4) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther4);
        }
      });
      return sum.toNumber();
    },
    discountOther5Calc() {
      let sum = BigNumber(0);
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.discountOther5) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther5);
        }
      });
      return sum.toNumber();
    },
    paymentCalc() {
      let sum = 0;
      this.main10ElecCo.forEach((monthly) => {
        if (monthly.payment) {
          sum += monthly.payment;
        }
      });
      return sum;
    },
    newEnergyUseCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.energyUse) {
          sum += monthly.energyUse;
        }
      });
      return sum;
    },
    newEnergyStorageCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.energyStorage) {
          sum += monthly.energyStorage;
        }
      });
      return sum;
    },
    newEnergyColdCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.energyCold) {
          sum += monthly.energyCold;
        }
      });
      return sum;
    },
    newEnergyOfficeCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.energyOffice) {
          sum += monthly.energyOffice;
        }
      });
      return sum;
    },
    newEnergyIceCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.energyIce) {
          sum += monthly.energyIce;
        }
      });
      return sum;
    },
    newEnergyFrozenCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.energyFrozen) {
          sum += monthly.energyFrozen;
        }
      });
      return sum;
    },
    newEnergyOtherCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.energyOther) {
          sum += monthly.energyOther;
        }
      });
      return sum;
    },
    newCostBaseCalc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costBase) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costBase);
        }
      });
      return sum.toNumber();
    },
    newCostEnergyCalc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costEnergy) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costEnergy);
        }
      });
      return sum.toNumber();
    },
    newCostFuelCalc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costFuel) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costFuel);
        }
      });
      return sum.toNumber();
    },
    newCostReEnergyCalc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costReEnergy) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costReEnergy);
        }
      });
      return sum.toNumber();
    },
    newCostOther1Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costOther1) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costOther1);
        }
      });
      return sum.toNumber();
    },
    newCostOther2Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costOther2) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costOther2);
        }
      });
      return sum.toNumber();
    },
    newCostOther3Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costOther3) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costOther3);
        }
      });
      return sum.toNumber();
    },
    newCostStorageCalc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.costStorage) {
          sum = BigNumber(sum.toNumber()).plus(monthly.costStorage);
        }
      });
      return sum.toNumber();
    },
    newDiscountOther1Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.discountOther1) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther1);
        }
      });
      return sum.toNumber();
    },
    newDiscountOther2Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.discountOther2) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther2);
        }
      });
      return sum.toNumber();
    },
    newDiscountOther3Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.discountOther3) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther3);
        }
      });
      return sum.toNumber();
    },
    newDiscountOther4Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.discountOther4) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther4);
        }
      });
      return sum.toNumber();
    },
    newDiscountOther5Calc() {
      let sum = BigNumber(0);
      this.newElecCo.forEach((monthly) => {
        if (monthly.discountOther5) {
          sum = BigNumber(sum.toNumber()).plus(monthly.discountOther5);
        }
      });
      return sum.toNumber();
    },
    newPaymentCalc() {
      let sum = 0;
      this.newElecCo.forEach((monthly) => {
        if (monthly.payment) {
          sum += monthly.payment;
        }
      });
      return sum;
    },
    selfGeneration1Calc() {
      let sum = 0;
      this.selfGeneration.forEach((monthly) => {
        if (monthly.selfGeneration1) {
          sum += monthly.selfGeneration1;
        }
      });
      return sum;
    },
    selfGeneration2Calc() {
      let sum = 0;
      this.selfGeneration.forEach((monthly) => {
        if (monthly.selfGeneration2) {
          sum += monthly.selfGeneration2;
        }
      });
      return sum;
    },
    selfGeneration3Calc() {
      let sum = 0;
      this.selfGeneration.forEach((monthly) => {
        if (monthly.selfGeneration3) {
          sum += monthly.selfGeneration3;
        }
      });
      return sum;
    },

    /* 前年回答合計 10大電力 */
    // 前年回答合計 使用電力量
    prevEnergyUseCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.energyUse;
      }, 0);
    },
    // 前年回答合計 蓄熱電力量
    prevEnergyStorageCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.energyStorage;
      }, 0);
    },
    // 前年回答合計 冷蔵電力量(荷捌室含む)
    prevEnergyColdCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.energyCold;
      }, 0);
    },
    // 前年回答合計 事務棟電力量
    prevEnergyOfficeCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.energyOffice;
      }, 0);
    },
    // 前年回答合計 製氷電力量
    prevEnergyIceCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.energyIce;
      }, 0);
    },
    // 前年回答合計 凍結電力量
    prevEnergyFrozenCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.energyFrozen;
      }, 0);
    },
    // 前年回答合計 その他電力量
    prevEnergyOtherCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.energyOther;
      }, 0);
    },
    // 前年回答合計 基本料金
    prevCostBaseCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costBase;
      }, 0);
    },
    // 前年回答合計 電力量料金
    prevCostEnergyCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costEnergy;
      }, 0);
    },
    // 前年回答合計 燃料費調整額
    prevCostFuelCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costFuel;
      }, 0);
    },
    // 前年回答合計 再エネ賦課金等
    prevCostReEnergyCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costReEnergy;
      }, 0);
    },
    // 前年回答合計 項目入力欄1
    prevCostOther1Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costOther1;
      }, 0);
    },
    // 前年回答合計 項目入力欄2
    prevCostOther2Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costOther2;
      }, 0);
    },
    // 前年回答合計 項目入力欄3
    prevCostOther3Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costOther3;
      }, 0);
    },
    // 前年回答合計 産業用蓄熱調整契約
    prevCostStorageCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.costStorage;
      }, 0);
    },
    // 前年回答合計 割引入力欄1
    prevDiscountOther1Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.discountOther1;
      }, 0);
    },
    // 前年回答合計 割引入力欄2
    prevDiscountOther2Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.discountOther2;
      }, 0);
    },
    // 前年回答合計 割引入力欄3
    prevDiscountOther3Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.discountOther3;
      }, 0);
    },
    // 前年回答合計 割引入力欄4
    prevDiscountOther4Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.discountOther4;
      }, 0);
    },
    // 前年回答合計 割引入力欄5
    prevDiscountOther5Calc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.discountOther5;
      }, 0);
    },
    // 前年回答合計 支払電力料金
    prevPaymentCalc() {
      return this.prevMain10ElecCo.reduce((sum, e) => {
        return sum + e.payment;
      }, 0);
    },

    /* 前年回答合計 新電力 */
    // 前年回答合計 使用電力量
    prevNewEnergyUseCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.energyUse;
      }, 0);
    },
    // 前年回答合計 蓄熱電力量
    prevNewEnergyStorageCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.energyStorage;
      }, 0);
    },
    // 前年回答合計 冷蔵電力量(荷捌室含む)
    prevNewEnergyColdCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.energyCold;
      }, 0);
    },
    // 前年回答合計 事務棟電力量
    prevNewEnergyOfficeCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.energyOffice;
      }, 0);
    },
    // 前年回答合計 製氷電力量
    prevNewEnergyIceCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.energyIce;
      }, 0);
    },
    // 前年回答合計 凍結電力量
    prevNewEnergyFrozenCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.energyFrozen;
      }, 0);
    },
    // 前年回答合計 その他電力量
    prevNewEnergyOtherCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.energyOther;
      }, 0);
    },
    // 前年回答合計 基本料金
    prevNewCostBaseCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costBase;
      }, 0);
    },
    // 前年回答合計 電力量料金
    prevNewCostEnergyCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costEnergy;
      }, 0);
    },
    // 前年回答合計 燃料費調整額
    prevNewCostFuelCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costFuel;
      }, 0);
    },
    // 前年回答合計 再エネ賦課金等
    prevNewCostReEnergyCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costReEnergy;
      }, 0);
    },
    // 前年回答合計 項目入力欄1
    prevNewCostOther1Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costOther1;
      }, 0);
    },
    // 前年回答合計 項目入力欄2
    prevNewCostOther2Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costOther2;
      }, 0);
    },
    // 前年回答合計 項目入力欄3
    prevNewCostOther3Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costOther3;
      }, 0);
    },
    // 前年回答合計 産業用蓄熱調整契約
    prevNewCostStorageCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.costStorage;
      }, 0);
    },
    // 前年回答合計 割引入力欄1
    prevNewDiscountOther1Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.discountOther1;
      }, 0);
    },
    // 前年回答合計 割引入力欄2
    prevNewDiscountOther2Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.discountOther2;
      }, 0);
    },
    // 前年回答合計 割引入力欄3
    prevNewDiscountOther3Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.discountOther3;
      }, 0);
    },
    // 前年回答合計 割引入力欄4
    prevNewDiscountOther4Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.discountOther4;
      }, 0);
    },
    // 前年回答合計 割引入力欄5
    prevNewDiscountOther5Calc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.discountOther5;
      }, 0);
    },
    // 前年回答合計 支払電力料金
    prevNewPaymentCalc() {
      return this.prevNewElecCo.reduce((sum, e) => {
        return sum + e.payment;
      }, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.flex-center {
  align-items: center;
}
.blue-text {
  color: blue;
}
.green-text {
  color: green;
}
.gray-back {
  background-color: #BDBDBD;
}
.red-back {
  background-color: #FFCDD2;
}
.green-back {
  background-color: #A5D6A7;
}
.blue-back {
  background-color: #90CAF9;
}
.yellow-back {
  background-color: rgb(255, 255, 204);
}
.yellow-back.autoInsertRow:not(:nth-of-type(1)) {
  background-color: #fff;
}
.border {
  padding: 1px 3px;
}
.underline {
  text-decoration: underline;
}
.v-input {
  padding-top: 0 !important;
}
.paddingToMargin {
  padding: 0;
  margin: 0px 12px 0px 12px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.vertical {
  writing-mode: vertical-rl;
  text-align: center;
}
ul {
  list-style: none;
}

/** 注意書き */
.add-note {
  white-space: pre-line;
}

/** タブ */
.v-slide-group__wrapper {
  height: 25px !important;
}
.v-tabs ::v-deep .v-tabs-bar__content {
  height: 25px !important;
}
.v-tabs ::v-deep .v-tabs-bar {
  height: 25px !important;
}
.v-tabs ::v-deep .v-slide-group__wrapper {
  border-bottom: 1px solid var(--v-primary-base);
}

.v-tabs ::v-deep .v-tabs-bar__content {
  .v-tab {
    border-top: 1px solid var(--v-primary-base);
    border-right: 1px solid var(--v-primary-base);
    border-bottom: 1px solid var(--v-primary-base);
  }
  .v-tab:first-of-type {
    border-left: 1px solid var(--v-primary-base);
  }
}

.v-tab {
  height: 25px !important;
  font-weight: bold;
}
.v-tab:hover {
  background-color: var(--v-info_hover-base) !important;
}
.v-tab--active {
  background-color: var(--v-info-base);
}
.v-tab--active:hover {
  background-color: var(--v-info-base);
}



.tab-width-200 {
  width: 200px;
  min-width: 200px;
}

.tab-item {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 180px);
}

.modal-wrap {
  height: 100%;
}
.modal-header {
  height: 10%;
}
.modal-header h2 {
  align-items: center;
  display: inline-flex;
}
.modal-body {
  overflow-y: scroll;
  height: 90%;
}

.main10-table-wrap {
  height: calc(100vh - 400px);
  overflow:auto
}
.new-table-wrap {
  height: calc(100vh - 361px);
}

.annotation {
  cursor: pointer;
  text-decoration: underline;
}

table {
  white-space: nowrap;
  border-spacing: 0;
  overflow: auto;
  width: 100%;
  border-collapse: separate;
  
  thead tr:nth-of-type(1) {
    th {
      position: sticky !important;
      position: -webkit-sticky !important;
      top: 0px;
      z-index: 50;
      background: white;
      border-top: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }
    th:first-of-type {
      border-left: 1px solid #000 !important;
      left: 0px;
      z-index: 51;
    }
    th:nth-of-type(2) {
      left: 250px;
      z-index: 51;
    }
    th:nth-of-type(n - 2) {
      border-right: 1px solid #000 !important;
    }
  }
  thead tr:nth-of-type(2) {
    th {
      position: sticky !important;
      position: -webkit-sticky !important;
      top: 26px;
      z-index: 50;
      background: white;
      border-bottom: 1px solid #000 !important;
    }

    th:nth-of-type(n - 2) {
      border-right: 1px solid #000 !important;
    }
  }

  tbody {
    th {
      padding: 0 8px;
      border-bottom: 1px solid #000 !important;
      border-right: 1px solid #000 !important;
    }
    tr:nth-of-type(1) {
      th:first-of-type {
        border-left: 1px solid #000 !important;
      }
    }
    tr:nth-of-type(2) {
      th:first-of-type {
        border-left: 1px solid #000 !important;
      }
    }
    tr:nth-of-type(6) {
      th:first-of-type {
        border-left: 1px solid #000 !important;
      }
    }
    tr:nth-of-type(11) {
      th:first-of-type {
        border-left: 1px solid #000 !important;
      }
    }
    tr:nth-of-type(19) {
      th:first-of-type {
        border-left: 1px solid #000 !important;
      }
    }
    tr:nth-of-type(n+25) {
      th:first-of-type {
        border-left: 1px solid #000 !important;
      }
    }

    td {
      padding: 0 8px;
      border-right: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }

    .fixed-item-1 {
      position: sticky !important;
      position: -webkit-sticky !important;
      background: white;
      left: 0px;
      z-index: 50;
    }
    .fixed-item-2 {
      position: sticky !important;
      position: -webkit-sticky !important;
      background: white;
      left: 50px;
      z-index: 50;
    }
    .fixed-item-3 {
      position: sticky !important;
      position: -webkit-sticky !important;
      background: white;
      left: 250px;
      z-index: 50;
    }

    .tr-total th{
      position: sticky !important;
      position: -webkit-sticky !important;
      background: white;
      left: 0px;
      z-index: 50;
    }
  }

  .selfTbody {
    tr:nth-of-type(2) {
      th:first-of-type {
        border-left: 0px solid #000 !important;
      }
    }
  }
}
tbody ::v-deep .v-text-field {
  padding-top: 0;
  color: inherit !important;
  input {
    color: inherit;
  }
}
</style>
